import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import axiosInstance from '../../api/api';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';  
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';


export default function SiparisTamamla() {
  const navigate = useNavigate();
  const [sepeturunler,setSepeturunler] = useState([]);
  const [sepetaciklama,setSepetaciklama] = useState([]);
  const [odemetip,setOdemetip] = useState(0);
  const [siparisid,setSiparisid] = useState(0);
  const [result,setResult] = useState("Teşekkürler! Bir siparişi daha tamamladınız.")
  const [state,setState] = useState({aratoplam:0.0,kdvtoplam:0.0,toplam:0.0,indirim:0.0,mfindirim:0.0}); 
  const [popupVisible, setPopupVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

    function opentab(evt, idname) {

    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(idname).style.display = "block";
    evt.currentTarget.className += " active";

    } 

    const [ayar,setAyar] = useState({
        stokGoster: "0",
        fiyatGoster: true,
        iskGoster: true,
        b2bAmbarKodu: "-1",
        negatifTumAmbarKontrol: true,
        negatifStokSiparisKayit: false,
        fiyatSira: "-1",
        acikHesapOdeme: true,
        krediOdeme: true,
        eczaciModulu: false,
        altKategoriGozuksun: true,
        kategoriGozuksun: true,
        kdvliFiyatGozuksun: true,
        kdvsizFiyatGozuksun: true,
        mfAktarimTipi: "0"
      }); 

    useEffect(()=>{ 
 
        const fetchdata = async() =>{ 
            
            const res= await axiosInstance.get("Erp/GetUygulamaAyarlari");
            setAyar(res.data);  

            const response = await axiosInstance.get("Sepet/GetSepet")
            if(response!=null && response.status==200){
                setSepeturunler(response.data.data.detaylar);
                setSepetaciklama(response.data.data.aciklama)  
            }
        }  
        fetchdata(); 
                
             
        const handleResize = () => {
        setIsMobile(window.innerWidth < 768); // Örneğin, 768 pikselin altındaki genişlikleri mobil olarak kabul ediyoruz
        };

        handleResize(); 
        window.addEventListener('resize', handleResize); 
        return () => {
        window.removeEventListener('resize', handleResize);
        };
  
    },[]); 
 
    useEffect(() => {
        updatePrices();
    }, [sepeturunler,odemetip]);

    useEffect(()=>{
        if(sepeturunler){
            if(ayar.acikHesapOdeme){
                OdemeTipiBelirle(0) 
            }
            else{
                OdemeTipiBelirle(1)  
            }
        }
    },[ayar]); 
    
    const updatePrices = () => {
        var aratoplamcalc = 0;
        var indirimcalc = 0;
        var kdvcalc = 0;
        var faturaNetcalc = 0;
        var kartindirimcalc = 0;
        var miktar  = 0;
        var mfindirimcalc = 0;

        for (let index = 0; index < sepeturunler.length; index++) {
            const element = sepeturunler[index];
            
            if(odemetip==0){
                if(element.mfAktif){
                    if(element.mfSira=="1"){
                        miktar = (element.malFazlasi + element.malFazlasiHediyesi) 
                        kdvcalc += element.kdvTutari * element.malFazlasi; 
                        indirimcalc += element.iskTutari * element.malFazlasi;   
                        mfindirimcalc += element.mfIskTutari *  element.malFazlasi;
                    }
                    else if(element.mfSira=="2"){
                        miktar = (element.malFazlasi2 + element.malFazlasiHediyesi2) 
                        kdvcalc += element.kdvTutari * element.malFazlasi2;   
                        indirimcalc += element.iskTutari * element.malFazlasi2;  

                        mfindirimcalc += element.mfIskTutari *  element.malFazlasi2;
                    }
                    else if(element.mfSira=="3"){
                        miktar = (element.malFazlasi3 + element.malFazlasiHediyesi3) 
                        kdvcalc += element.kdvTutari * element.malFazlasi3; 
                        indirimcalc += element.iskTutari * element.malFazlasi3;  

                        mfindirimcalc += element.mfIskTutari *  element.malFazlasi3;
                    } 

                    aratoplamcalc += element.birimFiyati * miktar;   
                    faturaNetcalc += element.mfIleFaturaNet * miktar;  

                }
                else{  
                    miktar = element.miktar;
                    faturaNetcalc += element.faturaNet * miktar;
                    aratoplamcalc += element.birimFiyati * miktar;  
                    kdvcalc += element.kdvTutari * miktar; 
                    indirimcalc += element.iskTutari * miktar;  
                    mfindirimcalc += 0;  
                }    
            }
            else{
                //Hesaplamalar 
                indirimcalc += element.eftKartIleIskTutari * element.miktar;  
                kdvcalc += element.eftKartIleKdvTutari * element.miktar;
                faturaNetcalc += element.eftKartIleFaturaNet * element.miktar;
                kartindirimcalc +=  (element.eftKartIleIskTutari-element.iskTutari) * element.miktar;
                aratoplamcalc += element.birimFiyati * element.miktar;   
            }
         }
 
         setState({aratoplam:ConvertIntToN2(aratoplamcalc),kdvtoplam:ConvertIntToN2(kdvcalc),toplam:faturaNetcalc,indirim:ConvertIntToN2(indirimcalc),kartIndirim:kartindirimcalc,mfindirim:mfindirimcalc}); 

    }; 
     
    const Kaydet = () =>{
        const PostData = async() =>{
            let requestdto = {
                belgeNo: odemetip.toString()
            }
            var res = await axiosInstance.post("Siparis/CreateSiparis",requestdto); 

            if(res!=null){
                if(res.status == 200){
                    setSiparisid(res.data.data);  
                    document.querySelector("#OdemeTuru").style.display='none';
                    document.querySelector("#SiparisNumarasi").style.display='block';
    
                    document.querySelector(".tablinks:first-child").className='tablinks ';
                    document.querySelector(".tablinks:last-child").className='tablinks active';  
    
                    toast.success("Sipariş Başarıyla Oluşturuldu");
                    if(odemetip==1){ 
                        setTimeout(()=>{
                            setPopupVisible(true); 
                        },1000) 
                    } 
    
                    setSepeturunler([]);
                    setSepetaciklama("");
    
                }
                else if(res.response.status == 400){
                    toast.error("Başarısız:"+res.response.data); 
                }
                else{}
            }
        }

        if(sepeturunler.length>0){
            PostData();
        }
        else{
            toast.error("Sepette Ürün Bulunmamaktadır.");
        }
    }
    
    const OdemeTipiBelirle = (type) =>{    

        if(type==1){ 
            document.getElementById("paymentMethod03").checked = true;  
            if(document.getElementById("paymentMethod02")){document.getElementById("paymentMethod02").checked = false}
        }else{
            document.getElementById("paymentMethod02").checked = true;   
            if(document.getElementById("paymentMethod03")){document.getElementById("paymentMethod03").checked = false}

        }
        setOdemetip(type)
        updatePrices();
    }

    const ConvertIntToN2 = (number) =>{
        if(number!=null){
      
          const str = number.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
          return str;
        }else{
          return null;
        }
    }
 
    const hidePopup = () => {
        setPopupVisible(false);
    };

    return (
      <>
      <Header anadizin="Sepet" baslik="Sipariş Kaydetme Ekranı" detaydizin="Siparis Tamamla" anadizinyol="/Sepet" />
      
      <div className='row page-content-wrapper'>
          
        <Popup
            visible={popupVisible}
            onHiding={hidePopup}
            dragEnabled={false} 
            showTitle={true}
            title="Mesaj!" 
            width={isMobile ? 350 : 400}
            height={200}
            position={{ my: 'center', at: 'center', of: window }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <p style={{ marginBottom: 20 }}>Ödeme Ekranına Gitmek İster Misiniz?</p>
                <div className='row justify-content-end'>
                    <button className='me-2 ms-2 btn btn-secondary col-sm-auto mb-2' onClick={()=>{ 
                        setPopupVisible(false); 
                        toast.info("Ödeme Ekranına Yönlediriliyorsunuz...",{
                            autoClose:1500
                        });
                        setTimeout(()=>{
                            navigate("/Odeme/"+siparisid); 
                        },2000)
                    }}>Evet</button>
                    <button className='me-2 ms-2 btn btn-light border col-sm-auto mb-2' onClick={hidePopup}>Hayır</button>
                </div>

            </div>
        </Popup>
        <div className='col-xl-8 col-lg-12'>
            <div>
                <div className="tab">
                <button className="tablinks active" onClick={(e)=>opentab(e, 'OdemeTuru')} >Ödeme Türü</button>
                <button className="tablinks" onClick={(e)=>opentab(e, 'SiparisNumarasi')}>Sipariş Numarası</button>
                </div>

            </div>
            <div className="card">
                <div className="card-body ">
                    
                <div id="OdemeTuru" className="tabcontent" style={{display:'block'}}  >
                    
                        <div>
                            <h5 className="mb-1">Ödeme Yöntemi Seçimi</h5>
                            <p className="text-muted mb-4">Lütfen Ödeme Yöntemi Seçiniz.</p>
                        </div>

                        <div className="row g-4">
                        {
                            ayar.krediOdeme  ? 
                                (
                                <div className="col-lg-4" onClick={()=>OdemeTipiBelirle(1)} >
                                    {/*!*/}
                                    <div data-bs-toggle="collapse" data-bs-target="#paymentmethodCollapse.show" aria-expanded="false" aria-controls="paymentmethodCollapse">
                                        <div className="form-check card-radio">
                                        <input id="paymentMethod03"  type="radio" className="form-check-input" />
                                        <label className="form-check-label" htmlFor="paymentMethod03">
                                            <span className="fs-14 text-muted me-2">
                                            </span>
                                            <span className="fs-14 text-wrap">Kredi Kartı</span>
                                        </label>
                                        </div>
                                    </div>
                                </div> 
                            ) : ""
                        }
                        {
                            ayar.acikHesapOdeme  ? 
                            (
                                
                                <div className="col-lg-4 " onClick={()=>OdemeTipiBelirle(0)}> 
                                    <div data-bs-toggle="collapse" data-bs-target="#paymentmethodCollapse" aria-expanded="true" aria-controls="paymentmethodCollapse">
                                    <div className="form-check card-radio">
                                        <input id="paymentMethod02"  type="radio" className="form-check-input" />
                                        <label className="form-check-label" htmlFor="paymentMethod02">
                                        <span className="fs-16 text-muted me-2">
                                        </span>
                                        <span className="fs-14 text-wrap">Açık Hesap</span>
                                        </label>
                                    </div>
                                    </div>
                                </div>
                            )
                            :""
                        }

                        </div>
                        
                    
                    <div className="d-flex align-items-start gap-3 mt-4">      
                        <button className="btn btn-primary btn-label right ms-auto nexttab" onClick={Kaydet} type="button">
                        
                            <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2"></i>Siparişi Tamamla
                                                
                    </button>
                    </div>

                    
                </div>

                <div id="SiparisNumarasi" className="tabcontent">
                    <div className="text-center py-5">

                        <div className="mb-4">
                        </div>
                        <h5 >{result}</h5>
                        <p className="text-muted">
                            Siparişinizin aşağıdan göz atabilrsiniz.
                        </p>

                        <h3>Sipariş ID: <button className="text-decoration-underline border-0 bg-light" onClick={()=>{navigate("/Siparis/"+siparisid)}}>{siparisid}</button></h3>
                    </div>
                </div>
                </div> 
            </div>
        </div>

        <div className="col-xl-4 col-lg-12">
            <div className="card">
            <div className="card-header">
                <div className="d-flex">
                    <div className="flex-grow-1">
                        <h5 className="card-title mb-0">Sipariş Detayları</h5>
                    </div>
                </div>
            </div>
            <div className="card-body p-0">
                <div className="table-responsive table-card">
                <table className="table table-borderless align-middle mb-0">
                    <thead className="table-light text-muted">
                            <tr>
                                <th style={{width: '90px'}} scope="col">Resim</th>
                                <th scope="col">Ürün Adı</th>   
                                <th scope="col" className="text-end">Fiyat</th>
                            </tr>
                    </thead>
                    <tbody>
                    { 
                        sepeturunler.map((detay,index) => 
                        { 
                            let mf;
                            let mfhediyesi;


                            if (detay.mfAktif) {
                                if (detay.mfSira === "1") {
                                    mf = detay.malFazlasi;
                                    mfhediyesi= detay.malFazlasiHediyesi;
                                } else if (detay.mfSira === "2") {
                                    mf = detay.malFazlasi2;
                                    mfhediyesi= detay.malFazlasiHediyesi2;
                                } else if (detay.mfSira === "3") {
                                    mf = detay.malFazlasi3;
                                    mfhediyesi= detay.malFazlasiHediyesi3;
                                }
                            }   
                            return (
                                <>
                                    <tr key={detay.id}>
                                        <td >
                                            <div className="avatar-md bg-light rounded p-0" width={120}>
                                                <img src={detay.resim1 == '' ? "/assets/img/default-product.jpg" :detay.resim1} style={{objectFit:'contain',width:'100%',height:'100%'}} ></img>
                                            </div>
                                        </td>
                                        <td style={{maxWidth:"350px"}}>
                                            <h5 className="fs-14">
                                                <Link  to={"/Magaza/"+detay.malzemeId}>
                                                    <span >{detay.adi}</span>    
                                                </Link> 
                                            </h5>
                                            <p className="text-muted mb-0">{ConvertIntToN2(odemetip ? detay.eftKartIleFaturaNet :( detay.mfAktif && ayar.mfAktarimTipi=="1" ? detay.mfIleFaturaNet : detay.faturaNet))} TL x 
                                            {" "+ConvertIntToN2(detay.mfAktif ? ayar.mfAktarimTipi == "0" ?  mf : mf + mfhediyesi: detay.miktar) } </p>
                                        </td>
                                        <td className="text-end" >{(ConvertIntToN2((odemetip ?  detay.eftKartIleFaturaNet : detay.mfAktif && ayar.mfAktarimTipi=="1" ? detay.mfIleFaturaNet : detay.faturaNet)* (
                                            detay.mfAktif ? ayar.mfAktarimTipi == "0" ?  mf : mf + mfhediyesi 
                                            
                                            : detay.miktar))) } TL</td>
                                        
                                    </tr>
                                    {
                                        
                                        detay.mfAktif && ayar.mfAktarimTipi == "0" ? (

                                            <tr key={`second-${index}`}>
                                                <td >
                                                    <div className="avatar-md bg-light rounded p-0" width={120}>
                                                        <img src={detay.resim1 == '' ? "/assets/img/default-product.jpg" :detay.resim1} style={{objectFit:'contain',width:'100%',height:'100%'}} ></img>
                                                    </div>
                                                </td>
                                                <td style={{maxWidth:"350px"}}>
                                                    <h5 className="fs-14">
                                                        <Link  to={"/Magaza/"+detay.malzemeId}>
                                                            <span >{detay.adi}</span>    
                                                        </Link> 
                                                    </h5>
                                                    <p className="text-muted mb-0">{ConvertIntToN2(odemetip ? detay.eftKartIleFaturaNet : detay.faturaNet)} TL x {ConvertIntToN2(mfhediyesi) } </p>
                                                </td>
                                                <td className="text-end" >{(ConvertIntToN2((0))) } TL</td>
                                                
                                            </tr>
                                        ):null
                                    }
                                </>
                            )
                        }
                        ) 
                        
                    }

                        <tr>
                            <td colSpan={2} >Ara Toplam:</td>
                            <td className="text-end" colSpan={2}  id="cart-subtotal">{ConvertIntToN2(state.aratoplam)} TL</td>
                        </tr>
                        <tr>
                            <td colSpan={2} >
                                <div>Genel İndirim<span className="text-muted" /> : </div>
                                {
                                    ayar.eczaciModulu  ?  (
                                        <div className='fw-medium  ps-1' style={{fontWeight:500}}>Mf İndirimi<span className="text-muted" /> : </div>
                                    ): ""
                                }

                                {
                                    odemetip == 1 ?  (
                                        <div className='fw-medium  ps-1' style={{fontWeight:500}}>Kart İle Ödeme İndirimi<span className="text-muted" /> : </div>
                                    ): ""
                                }
                                

                            </td>
                            <td className="text-end" id="cart-discount">
                                <div>{ConvertIntToN2(state.indirim)} TL</div>
                                {
                                    ayar.eczaciModulu  ?  ( 
                                        <div className='fw-medium pe-1'>{ConvertIntToN2(state.mfindirim)} TL</div>
                                    ):""
                            }
                            {
                                    odemetip == 1 ?  ( 
                                        <div className='fw-medium pe-1'>{ConvertIntToN2(state.kartIndirim)} TL</div>
                                    ):""
                            }

                            </td>
                        </tr>  
                        <tr>
                            <td colSpan={2} >KDV: </td>
                            <td className="text-end" id="cart-tax">{ConvertIntToN2(state.kdvtoplam)}  TL</td>
                        </tr>
                        <tr className="table-active">
                            <th colSpan={2} >Toplam  :</th>
                            <td className="text-end">
                                <span className="fw-semibold" id="cart-total">
                                {ConvertIntToN2(state.toplam)} TL
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
            </div>
        </div>
        
        <div className='offset-xl-8 col-xl-4 col-lg-12'>
            <div className='card'> 
                <div className="card-header">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h5 className="card-title mb-0">Sipariş Açıklaması</h5>
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    {sepetaciklama}
                </div>
            </div>
        </div>
      
      </div>
      </>
    )
}

