import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import axiosInstance from "../../api/api"; 
import { Link } from "react-router-dom"; 
import authService from "../../services/auth-services";

export default  function Home() { 
  const [bildirimler, setbildirimler] = useState([]);
  const [slaytlar, setslaytlar] = useState([]);
  const [tip, setTip] = useState(0);

  useEffect(() => {
    fetchdata();
  }, [tip]);

  const fetchdata = async () => {
      const res = await axiosInstance.get("Erp/GetMain?tip=" + tip); 
      if(res!=null && res.status == 200){
          setbildirimler(res.data.data.btoBbildirimleri);
          setslaytlar(res.data.data.btoBslaytlari);
      } 
  };

  const formatTarih = (tarih) => {
    const tarihObj = new Date(tarih);

    const gunler = [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ];
    const aylar = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ];

    const gun = tarihObj.getDate();
    const ay = aylar[tarihObj.getMonth()];
    const yil = tarihObj.getFullYear();

    const saat = tarihObj.toLocaleString("tr-TR", {
      hour: "numeric",
      minute: "numeric",
      hour24: true,
    });

    const bugun = new Date();
    const fark = new Date(tarihObj) - bugun;
    const dakikaFarki = Math.floor(fark / (1000 * 60));
    
    if (dakikaFarki < 1) {
      return saat + " Bugün";
    } else if (dakikaFarki < 60) {
      return saat;
    } else {
      return `${gun} ${ay}, ${yil}`;
    }
  };

  const tarihGetir = (tarih) => {
    const formatliTarih = formatTarih(tarih);
    return formatliTarih;
  };

  return (
    <div>
      <Header
        anadizin="Anasayfa"
        baslik="Anasayfa"
        detaydizin="Anasayfa"
        anadizinyol="/"
      />

      <div className=" page-content-wrapper">
        <div className="row gap-2 mb-3">
          <div className="col-xl-12 col-md-12 ">
            <h4 className="fs-16">Hoşgeldin {authService.getUnvan()}</h4>
            <p className="text-muted mb-0">
                Bugün mağazada neler oluyor?
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-lg-9 mb-3 col-md-12">
            <div className="row">
              <div className="col-12">
                
              <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        {slaytlar.length > 0 ? slaytlar.map((item, index) => (

                          <div key={index} className={"carousel-item  " + (index === 0 ? " active" : "")}>
                              <div className="d-block w-100" 
                              style={{height: 500,width:"100%",backgroundImage: `url(${item.resim} )`,backgroundSize: "cover",backgroundRepeat:"no-repeat",backgroundPosition:"center"}}>
                                    <div className="h-100 align-items-end d-flex position-absolute justify-content-center w-100">
                                      <div className="w-100 row bg-black justify-content-center opacity-75">
                                        <div className="col-8 text-center">
                                          <h4 className="fs-16 text-white py-3">
                                            {item.baslik}<br></br>
                                            { (item.link==null || item.link=="") ? "" :
                                                <Link to={item.link} className="text-white fs-14 text-muted">(Linke Git)</Link>
                                                 
                                            }
                                          </h4>
                                          <p className="text-white opacity-75">
                                            {item.detay}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                              </div>
                          </div>
                        )):""}
                    </div>
                    <a className="carousel-control-prev align-items-end" style={{bottom:"10%"}} href="#carouselExampleControls" role="button" data-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true" />
                    </a>
                    <a className="carousel-control-next  align-items-end" style={{bottom:"10%"}} href="#carouselExampleControls" role="button" data-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true" />
                    </a>
                  </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mb-3 col-md-12 rounded">
            <div className="card">
              <div className="card-body">
                <div className="p-1 mb-2 row">
                  <h6 className="col text-muted fs-14 mb-0 text-uppercase fw-medium">
                    BİLDİRİMLER
                  </h6>
                  <div className="col-auto ">
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn p-0"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="bi bi-gear "></i>
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => {
                            setTip(0);
                          }}
                        >
                          Tümü
                        </button>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => {
                            setTip(1);
                          }}
                        >
                          Sana Özel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="p-1 pt-1"
                  style={{
                    height: "430px",
                    maxHeight: "430px",
                    overflow: "hidden scroll",
                  }}
                >
                  {bildirimler.length > 0
                    ? bildirimler.map((item, index) => (
                        <div className="row mt-3" key={index}>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-1">
                                <i
                                  className="bi bi-basket text-success fs-14"
                                  style={{ zIndex: 10 }}
                                ></i>
                              </div>
                              <div className="col-11">
                                <h5 className="fs-14">{item.baslik}</h5>
                              </div>
                              <div className="offset-1 col-11">
                                <span className="fs-12">{item.detay}</span>
                                <small className="fs-11 d-block text-muted">
                                  {tarihGetir(item.tarih)}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
 