import React from 'react'

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <>
        <div className="w-100 container-fluid">
          <div className="row">
            <div className="col-6">
              <span>©{year} KöklüSağlıkBayi.</span>
             
            </div>
            {
              /*
                <div className="col-6"> 
                    <div className=' d-flex justify-content-end'>
                      <img className='ms-3' src='/assets/img/mc-logo.png' height={30}/>
                      <img className='ms-3'  src='/assets/img/visa-logo.png' height={20}/>
                    </div>
  
                </div>
              
              */
            }
          </div>
        </div>
  </>
  )
}

export default Footer