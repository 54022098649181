import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"; 


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
    <>  
        <App />
        <ToastContainer style={{maxWidth:"100%",padding:"10px 5px"}} />  
    </> 
); 
reportWebVitals();
