import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import axiosInstance from '../../api/api';  

export default function Odeme()  { 
    const {id} = useParams();
    var url = process.env.REACT_APP_PAYMENT_BASE_URL+"Home.aspx?siparisid="+id;
    const [urunler,setUrunler] = useState([]); 
    
    useEffect(()=>{
        const fetchdata =async () =>{
            let res = await axiosInstance.get("Siparis/GetOdemeDetay/"+id);
            if(res!=null){
                if(res.status == 200){
                    setUrunler(res.data.data.urunler) 
                }
            }
        }

        fetchdata(); 
    },[]);
    
    const AraToplamGetir = () =>{
        var deger = 0.0;
        
        if(urunler.length > 0){
            
            for (let index = 0; index < urunler.length; index++) { 
                const element = urunler[index];
                var elementdeger = 0;
                elementdeger += parseFloat(element.birimFiyati);
                elementdeger *= parseFloat(element.miktar);

                deger += elementdeger;
            }
        }

        return deger.toFixed(2);

    }

    const KdvGetir = () =>{
        var deger = 0.0;

        if(urunler.length > 0){
            
            for (let index = 0; index < urunler.length; index++) {
                
                const element = urunler[index];
                var elementdeger = 0;
                elementdeger += parseFloat(element.kdv);
                
                deger += elementdeger;
            }
        }
        return deger.toFixed(2);

    }

    const IndirimGetir = () =>{
        var deger = 0.0;

        if(urunler.length > 0){
            
            for (let index = 0; index < urunler.length; index++) {
                
                const element = urunler[index];
                var elementdeger = 0;
                elementdeger += parseFloat(element.isk);
                
                deger += elementdeger;
            }
        }
        return deger.toFixed(2);

    }

    const ToplamGetir = () => {
        if (!urunler ) return 0;

        const toplam = urunler.reduce((acc, curr) => acc + (curr.faturaNet), 0);
        return ConvertIntToN2(toplam);
    
    };
    
    const ConvertIntToN2 = (number) =>{
        if(number!=null){
        
            const str = number.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
            return str;
        }else{
            return null;
        }
    } 

    return (
    <div>
            {/*----------Page Content Header---------*/}
            <Header anadizin="Siparisler" baslik="Ödeme" detaydizin="Ödeme" anadizinyol="Siparisler/" />

            {/*----------Page Content Body---------*/}
        <div className="row page-content-wrapper">
            
            <div className="col-lg-8 col-md-12">
                <div className="card">
                    <div className="card-header">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                        <h5 className="card-title mb-0">Kredi Kartı İle Ödeme</h5>
                        </div>
                    </div>
                    </div>

                    <div className="card-body">
                        <form method="POST" action={url}>
                            <div className="row gy-3">
                                    <p className="text-muted">Kart Bilgilerinizi Giriniz.</p>
                                    <div className="col-md-12">
                                        <label htmlFor="cc-name" className="form-label">Kartın Üzerindeki İsim:</label>
                                        <input type="text" className="form-control" id="cc-name" name='CardHolderName' placeholder="Kartın üzerindeki tam ismi yazınız." />
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="cardnumber">Kart Numarası:</label>
                                            <input type="text" className="form-control mt-1" id="cardnumber" name='Pan'/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="skt">Son Kullanım Tarihi:</label>
                                            <input type="text" className="form-control mt-1" id="skt" name='Expiry'/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="cvvtext">CVV:</label>
                                            <input type="text" className="form-control mt-1" id="cvvtext" name='Cvv2'/>
                                        </div>
                                    </div> 
                                    <div className="col-md-12">
                                        <label htmlFor="taksittext" className="form-label">Taksit Seçenekleri:</label>
                                        <select className="form-control" id="taksittext">
                                            <option>Tek Çekim ~ {ToplamGetir()}</option>
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <input type="checkbox" className="form-check-input"  id="3dsecurebox"  defaultValue="something" checked={true} disabled={true}/>
                                        <label className="form-check-label me-2" htmlFor="3dsecurebox"><span className="fw-500 ">3d secure</span> ile ödeme yapmak istiyorum.</label>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start gap-3 mt-4">
                                    <button  className="btn btn-success btn-label right ms-auto nexttab" data-nexttab="pills-finish-tab">
                                    <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2" />Ödemeyi Tamamla
                                    </button>
                                </div> 
                                <input type="hidden" name="PurchAmount" value={ToplamGetir()} /> 
                        </form>
                    </div>
                </div>
            </div>

            
            
            <div className="col-xl-4">
                <div className="card">
                <div className="card-header">
                    <div className="d-flex">
                    <div className="flex-grow-1">
                        <h5 className="card-title mb-0">Sipariş Detayları</h5>
                    </div>
                    </div>
                </div>
                <div className="card-body p-0">
                    <div className="table-responsive table-card">
                    <table className="table table-borderless align-middle mb-0">
                        <thead className="table-light text-muted">
                        <tr>
                            <th style={{width: '90px'}} scope="col">Resim</th>
                            <th scope="col">Ürün Adı</th>   
                            <th scope="col" className="text-end">Fiyat</th>
                        </tr>
                        </thead>
                        <tbody>
                        {(urunler.length !=0) ? 
                            urunler.map((urun) =>
                                <tr key={urun.id}>
                                    <td>
                                        <div className="avatar-md bg-light rounded p-0" >
                                            <img src={urun.resim1 == '' ? "/assets/img/default-product.jpg" :urun.resim1} style={{objectFit:'contain',width:"100%",height:'100%'}}></img>

                                        </div>
                                    </td>
                                    <td>
                                        <h5 className="fs-14" style={{maxWidth:"350px"}}>{urun.adi}</h5>
                                        <p className="text-muted mb-0">{ConvertIntToN2(urun.faturaNet/urun.miktar)} TL x {ConvertIntToN2(urun.miktar) } </p>
                                    </td>
                                    <td className="text-end">{ConvertIntToN2(urun.faturaNet)} TL</td>
                                </tr>
                            ) : ""
                        }

                            <tr>
                                <td className="fw-semibold" colSpan={2}>Ara Toplam :</td>
                                <td className="fw-semibold text-end">{AraToplamGetir()} TL</td>
                            </tr>
                            <tr>
                                <td className="fw-semibold" colSpan={2}>Indirim:</td>
                                <td className="fw-semibold text-end">{IndirimGetir()} TL</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Kdv: </td>
                                <td className="text-end">{KdvGetir()} TL</td>
                            </tr>

                            <tr className="table-active">
                                <th colSpan={2}>Toplam:</th>
                                <td className="text-end">
                                    <span className="fw-semibold">
                                        {ToplamGetir()} TL
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                {/* end card body */}
                </div>
                {/* end card */}
            </div>
        </div>
    </div>
    );
};
 