import React from 'react'
import { Link } from 'react-router-dom'


export default function ServerError (){ 
  return (
    <div>
        
        <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
            {/* auth-page content */}
            <div className="auth-page-content overflow-hidden p-0">
            <div className="container-fluid">
                <div className="row justify-content-center">
                <div className="col-xl-4 text-center">
                    <div className="error-500 position-relative">
                        <img src="/assets/img/error500.png" className="img-fluid error-500-img error-img"/>

                        <h1 className="title text-muted">500</h1>
                    </div>
                    <div>
                        <h4>İnternal Server Error!</h4>
                        <p className="text-muted w-75 mx-auto">Server Error 500. Ne olduğundan tam olarak emin değiliz ama sunucularımız bir şeylerin ters gittiğini söylüyor.</p>
                        <Link to="/"  className="btn btn-primary">Anasayfaya Geri Dön</Link>
                    </div>
                </div>{/* end col*/}
                </div>
                {/* end row */}
            </div>
            {/* end container */}
            </div>
            {/* end auth-page content */}
        </div>
    </div>
  )
} 