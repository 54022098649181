import axios from "axios"; 
import authService from "../services/auth-services";
import Cookies from 'js-cookie'
import { toast } from 'react-toastify';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE_URL,
  headers: {
    "Content-Type": "application/json", 
  },
  
});

axiosInstance.interceptors.request.use(

  (config) => {
    
    const token =authService.getAccessToken();
    
    if (token) {
       config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end

    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use( 
  (res) => { 
    return res;
  },
  async (err) => {  
      console.log(err);  

      if(err.response.status==401){   
          toast.error("Oturum Verisi Geçerli Değil.Yönlendiriliyorsunuz..."); 
          window.location.href = "/Login"; 
          
          Cookies.remove("oturum");
          return null;
      }

      else if(err.response.status==500){
          toast.error("Server Failed:"+new Date().toLocaleDateString('tr-TR')+" "+new Date().toLocaleTimeString('tr-TR'));
          return null; 
      }
      
      else{ 
          return err;
      } 
  }
);
export default axiosInstance;