import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axiosInstance from '../../api/api';
import Header from '../../components/Header';
import  { DataGrid,Column, Paging, Pager, Sorting } from 'devextreme-react/data-grid'; 

export default function SiparisDetay (){
    const {id} = useParams();
    const [data,setData] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        const fetchdata = async() =>{
            const res = await axiosInstance.get("Siparis/GetSiparisDetaylari/" + id); 
            setLoading(true); 
            if(res!=null && res.status ==200 ){
            
                setData(res.data.data);
            }else{
                setError(res.response.data.mesaj);
            } 
           
            setLoading(false); 
        }

        fetchdata();
    },[id])   

    const aratoplamgetir = () => {
        if (!data || !data.siparisdetaydata) return 0;
    
        const toplam = data.siparisdetaydata.reduce((acc, curr) => acc + curr.birimFiyati * curr.miktar, 0);
        return ConvertIntToN2(toplam);
    };

    const toplam = () => {
    if (!data || !data.siparisdetaydata) return 0;

    const toplam = data.siparisdetaydata.reduce((acc, curr) => acc + (curr.faturaNet), 0);
    return ConvertIntToN2(toplam);
    };

    const kdvtoplam = () => {
        if (!data || !data.siparisdetaydata) return 0;

        const toplam = data.siparisdetaydata.reduce((acc, curr) => acc + (curr.kdv), 0);
        return ConvertIntToN2(toplam);
    };

    const indirimgetir = () =>{
    if (!data || !data.siparisdetaydata) return 0;

        const toplam = data.siparisdetaydata.reduce((acc, curr) => acc + (curr.isk)  , 0);
        return ConvertIntToN2(toplam);
    }

    const ConvertIntToN2 = (number) =>{
    if(number!=null){
    
        const str = number.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
        return str;
    }else{
        return null;
    }
    } 

    function onCustomizeColumns(columns){  
        for(var i = 0; i < columns.length; i++) {
          columns[i].cssClass = 'border-0';  
        } 
    } 
 
    return (
        <div>
            {/* Page Content Header */}
            <Header anadizin="Sipariş" baslik="Sipariş Detayı" detaydizin="Detay" anadizinyol="/Siparisler" />

            {loading && <p>Loading...</p>}
            {error && <span style={{ color: 'red' }}>{error}</span>}

            {/* Page Content Body */}
            { data && 
                <div className="row page-content-wrapper">
                    <div className="col-xl-9">
                    <div className="card">
                        <div className="card-header">
                        <div className="d-flex align-items-center">
                            <h5 className="card-title flex-grow-1 mb-0">Sipariş #{id}</h5>
                            <div className="flex-shrink-0">
                            {/*<button className="btn btn-success btn-sm">
                                <i className="bi bi-download align-middle me-1"></i>
                                Fatura
                                </button>*/}
                            </div>
                        </div>
                        </div>
                        <div className="card-body">
                            <DataGrid  customizeColumns={onCustomizeColumns} 
                                dataSource={data.siparisdetaydata} columnHidingEnabled={true}
                                showBorders={false}
                                keyExpr="id" id='siparisdetaytable' style={{width:"100%"}}
                                >
                                <Sorting mode="none" />
                                <Column  width={250} alignment='left' caption="Ürün Detayı" cellRender={({ data }) => 
                                        { 
                                            return (
                                        
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 avatar-md bg-light rounded p-0">
                                                        <img
                                                            src={data.resim1 != "" ? data.resim1 : "/assets/img/default-product.jpg"}
                                                            style={{objectFit:'contain',width:'100%',height:'100%'}}
                                                            alt={data.adi}
                                                        />
                                                    </div>
                                                    <div className="flex-grow-1 ms-3" style={{width:150}}>
                                                        <h5 style={{"whiteSpace":"pre-line"}} className="fs-14">
                                                            <Link to={`/Magaza/${data.id}`} className="link-primary">
                                                            {data.adi}
                                                            </Link>
                                                        </h5> 
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                />
                                <Column width={150}  dataField="birimFiyati" alignment='right' caption="Birim Fiyatı" cellRender={(render)=>{
                                    
                                    return (ConvertIntToN2(render.data.birimFiyati)+" TL")
                                }}/>
                                
                                <Column width={150}  dataField="miktar" alignment='right' caption="Miktarı"  cellRender={(render)=>{
                                    
                                    return (ConvertIntToN2(render.data.miktar) )
                                }}/>
                                <Column dataField="birim" alignment='right' caption="Birim" />
                                <Column width={150}  dataField="kdvoran" alignment='right' caption="Kdv Oranı"  cellRender={(render)=>{
                                    
                                    return ("%"+(render.data.kdvoran))
                                }}/>
                                <Column width={150}   dataField="isk" alignment='right' caption="İndirim" cellRender={(render)=>{
                                    
                                    return (ConvertIntToN2(render.data.isk)+" TL")
                                }}/>
                                <Column dataField="faturaNet" alignment='right' caption="Toplam Fiyat" cellRender={(render)=>{
                                    
                                    return (ConvertIntToN2(render.data.faturaNet)+" TL" )
                                }}/>
                                
                                <Pager
                                    showInfo={true}
                                    showNavigationButtons="compact"
                                    showPageSizeSelector={false}
                                    visible={false}
                                />
                            </DataGrid>

                            <div className='row pt-3 border-top border-dashed justify-content-end mt-4'>
                                <div className='col-md-6 col-sm-12 fs-14 fw-500'>
                                    <div className='row mb-3'>
                                        <div className='col'>Ara Toplam:</div>
                                        <div className='col-auto'>{ConvertIntToN2(data.siparisdata.brut)} TL</div>

                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col'>Toplam İndirim:</div>
                                        <div className='col-auto'>{indirimgetir()} TL</div> 
                                    </div> 
                                    
                                    <div className='row mb-3'>
                                        <div className='col '>Kdv:</div>
                                        <div className='col-auto'>{kdvtoplam()} TL</div>

                                    </div> 
                                    
                                    <div className='row pt-3 border-top border-dashed fw-600'>
                                        <div className='col '> Toplam:</div>
                                        <div className='col-auto'>{toplam()} TL</div>

                                    </div> 
                                </div>
                            </div> 
                        </div>
                    </div>
                    {/* end card */}
                    </div>
                    <div className="col-xl-3">
                    <div className="card">
                        <div className="card-header">
                        <div className="d-flex">
                            <h5 className="card-title flex-grow-1 mb-0">
                            <i className="mdi mdi-truck-fast-outline align-middle me-1 text-muted"></i> Sevk Durumu
                            </h5>
                            <div className="flex-shrink-0">
                            <a
                                href="#"
                                className={`badge badge-soft-${data.siparisdata.durumu === 0 ? "primary" : "danger"} fs-11`}
                            >
                                {data.siparisdata.durumu === 0 ? "Geçerli" : "İptal Edildi"}
                            </a>
                            </div>
                        </div>
                        </div>
                        <div className="card-body">
                        <div className="text-center">
                            <img
                            src={`/assets/img/${data.siparisdata.gerceklesmeDurumu == 0 ? "hourglass" :
                                data.siparisdata.gerceklesmeDurumu == 1 ? "verified" :
                                data.siparisdata.gerceklesmeDurumu == 2 ? "truck" :
                                data.siparisdata.gerceklesmeDurumu == 3 ? "invalid" : "invalid"}.gif`} 
                            style={{ width: '80px', height: '80px' }}
                            />

                            <p className="fs-13 my-1">
                                Gerçekleşme Durumu: 
                            {data.siparisdata.gerceklesmeDurumu == 0 ? ("Bekliyor") :
                                data.siparisdata.gerceklesmeDurumu == 1 ? ("Tamamı Sevk Edildi") :
                                data.siparisdata.gerceklesmeDurumu == 2 ? ("Kısmen Sevk Edildi") :
                                data.siparisdata.gerceklesmeDurumu == 3 ? ("Onaylanmadı"):
                                data.siparisdata.gerceklesmeDurumu == 4 ? ("Kapatıldı") : ""}
                            </p>
                            <p className="text-muted mb-0">Sipariş No: {data.siparisdata.no}</p>
                            <p className="text-muted mb-0">Ödeme :  {data.siparisdata.belgeNo === "0" ? "Açık Hesap" : "Kredi Kartı"}</p>
                             

                        </div>
                        </div>
                    </div>
                    {/* end card */}

                    <div className="card">
                        <div className="card-header">
                            <div className="d-flex">
                                <h5 className="card-title flex-grow-1 mb-0">Müşteri Detayları</h5>
                                <div className="flex-shrink-0">
                                
                                <Link to={`/MusteriEkstre/${data.caridata.cariId}`} className="link-secondary">
                                    Hareketlere Git
                                </Link>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                        <ul className="list-unstyled mb-0 vstack gap-3">
                            <li>
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                <img src="/assets/img/auth-one-bg.jpg" width={48} height={48} alt="" className="avatar-sm rounded" />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                <h6 className="fs-14 mb-1">{data.caridata.adi}</h6>
                                <p className="text-muted mb-0">{data.caridata.sirketTuru == 0 ? "Şahıs" : "Tüzel"}</p>
                                </div>
                            </div>
                            </li>
                            <li>
                            <i className="ri-mail-line me-2 align-middle text-muted fs-16"></i>
                            Email:{data.caridata.email}
                            </li>
                            <li>
                            <i className="ri-phone-line me-2 align-middle text-muted fs-16"></i>
                            Tel:{data.caridata.tel1}
                            </li>
                        </ul>
                        </div>
                    </div>
                    {/* end card */}

                    <div className="card"> 
                        <div className="card-header">
                            <div className="d-flex">
                                <h5 className="card-title flex-grow-1 mb-0">Ödeme Detayı</h5>
                                {
                                    data.siparisdata.belgeNo != "0" && data.tahsilatdata!=null ?
                                    (
                                        <div className="flex-shrink-0"> 
                                            <Link to={"/TahsilatGoruntule/"+data.siparisdata.siparisId} className="link-secondary">
                                                Tahsilat Makbuzu
                                            </Link>
                                        </div>
                                    ):"" 
                                }
                                
                            </div>
                        </div>
                        <div className="card-body">
                        <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                            <p className="text-muted mb-0">İşlem Numarası:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                            <h6 className="mb-0">*****</h6>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                            <p className="text-muted mb-0">Ödeme Yöntemi:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                            <h6 className="mb-0">
                                {data.siparisdata.belgeNo === "0" ? "Açık Hesap" : "Kredi Kartı"}
                            </h6>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                            <p className="text-muted mb-0">Kart Üzerindeki İsim:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                            <h6 className="mb-0">
                                {data.tahsilatdata==null ? "-----":data.siparisdata.ozelkod !== null
                                ? data.siparisdata.ozelkod !== ""
                                    ? data.tahsilatdata.kartSahibi.toString()
                                    : "-----"
                                : "-----"}
                            </h6>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                            <p className="text-muted mb-0">Kart Numarası:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                            <h6 className="mb-0">
                                {data.tahsilatdata==null ? "-----": data.tahsilatdata.kartNo}
                            </h6>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                            <p className="text-muted mb-0">Ödenen Tutar</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                            <h6 className="mb-0">
                                {data.tahsilatdata==null ? "-----": data.siparisdata.ozelkod !== null
                                ? data.siparisdata.ozelkod !== ""
                                    ? (ConvertIntToN2(data.tahsilatdata.tutar).toString()) + " TL"
                                    : "-----"
                                : "-----"}
                            </h6>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* end card */}
                    </div>
                    {/* end col */}
                </div>
                }
            
        </div>
    )
    
}
 