import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

function NavMenu() {
    useEffect(()=>{
      
      const mainwrapperdom = document.querySelector("#mainwrapper");
      const closelayerdom = document.querySelector(".close-layer"); 
      document.querySelectorAll(".nav-item").forEach((item)=>{
          item.addEventListener("click",function(){
          
            mainwrapperdom.classList.remove('nav-open')
            closelayerdom.classList.remove('visible'); 

          })
        })
    },[]);

    return (
      <div>
        <div className="logo">
          <Link to="/">
            <span style={{lineHeight: '70px'}}>
              <img src="/assets/img/koklulogo.png" height={200} style={{opacity:"0.8"}}/>
            </span>
          </Link>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav-link px-0">
            <li className="menu-title"><span data-key="t-menu">MENÜLER</span></li>

            <Link to="/" className="nav-item">
                <i className="bi bi-speedometer2 fs-18"></i>
                <span className="pt-2 px-1">Ana Menü</span>
            </Link>

            <Link to="/Magaza" className="nav-item">
                <i className="bi bi-shop  fs-18" />
                <span className="pt-2 px-1">Mağaza</span>
            </Link>

            <Link to="/Musteriler" className="nav-item">
                <i className="bi bi-person-badge  fs-18" />
                <span className="pt-2 px-1">Cari Hesaplar</span>
            </Link>

            <Link to="/Siparisler" className="nav-item">
                <i className="bi bi-journal-text  fs-18" />
                <span className="pt-2 px-1">Siparişler</span>
            </Link>

            <Link to="/Faturalar" className="nav-item">
                <i className="bi bi-receipt  fs-18" />
                <span className="pt-2 px-1">Faturalar</span>
            </Link>
          </ul>
        </div>
        <div className="sidebar-background" />
      </div>
    )
}

export default NavMenu