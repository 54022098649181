import React, { useEffect, useState } from 'react'
import Header from '../../components/Header' 
import { useParams } from 'react-router-dom'
import axiosInstance from '../../api/api'
import { toast } from 'react-toastify';
import { LoadingIndicator } from 'devextreme-react/bar-gauge'; 


export default function MalzemeDetay() {
    var {id} = useParams();
    const [malzemedata,setmalzemedata] = useState(null); 
    const [error, setError] = useState(''); 
    const [ayar,setAyar] = useState({
        stokGoster: "0",
        fiyatGoster: true,
        iskGoster: true,
        b2bAmbarKodu: "-1",
        negatifTumAmbarKontrol: true,
        negatifStokSiparisKayit: false,
        fiyatSira: "-1",
        acikHesapOdeme: true,
        krediOdeme: true,
        eczaciModulu: false,
        altKategoriGozuksun: true,
        kategoriGozuksun: true,
        kdvliFiyatGozuksun: true,
        kdvsizFiyatGozuksun: true,
        mfAktarimTipi: "0"
      }); 
     
    useEffect(()=>{  
        const fetchdata = async() =>{
            const resp = await axiosInstance.get("Malzeme/GetMalzeme/"+id);
            const res2 = await axiosInstance.get("Erp/GetUygulamaAyarlari"); 
            setAyar(res2.data); 

            if(resp!=null){
                if(resp.status == 200){
                    setmalzemedata(resp.data.data) 
                }
                else if(resp.response.status==404){
                    setError("Malzeme Bulunamadı"); 
                } 
                else{}

            }  
        }
        fetchdata(); 
    },[]); 
      
    
  const SepeteEkle = (mfsira=null,mfaktif=false) => { 
    var miktardom = parseFloat(document.querySelector('#miktar').value);
  
    if(mfaktif){
        miktardom = 1;
    }

    const requestDto = {
        MalzemeId: malzemedata.malzemeId,
        MfAktif: mfaktif,
        MfSira: mfsira,
        Miktar: miktardom
    };


    axiosInstance.post("Sepet/CreateSepetItem",requestDto).then(res=>{  
        if(res!=null){
            if(res.status == 200){
                toast.success("Sepete Eklendi.");   
            }
            else{ 
                var mesaj = res.response.data;
                toast.error(mesaj,{
                  autoClose:2000
                })
            }
        } 
    });   
  }   
 
    const ConvertIntToN2 = (number) =>{
        if(number!=null){ 
            const str = number.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
            return str;
        }
        else{
            return null;
        }
    }

    return (
        <div>
            <Header anadizin="Magaza" baslik="Ürün Detay" detaydizin="Detay" anadizinyol="/Magaza" /> 
            

            
                <div className="row page-content-wrapper">
                <div className='col-md-12'> 
                    {error ? (<span style={{ color: 'red' }}>{error}</span>) : ""}
                    {malzemedata ? ( 
                        <div className='card'>
                        <div className="card-header b-0">
                            <div className="row align-items-center ">
                                <div className="col">
                                    <h5 className="card-title mb-3 fs-16">Ürün Bilgileri</h5>
                                </div>
                                <div className="col-auto"> 
                                    <div className='row w-100 justify-content-center p-0'>
                                        <div className='d-flex' style={{maxWidth:300}}> 
                                            
                                        </div>    
                                    </div>  
                                </div>
                            </div>
                        </div>
                            <div className='card-body'>
                                <div className='row p-2'>
                                    <div className='col-lg-5 col-md-12'>
                                        <div className='row justify-content-xl-center justify-content-lg-start'>
                                            <div className='col-lg-10 col-md-12 pt-4'>
                                                <div className='row p-0 justify-content-center border' style={{backgroundColor:'white',height:"calc(100vh /2)",width:"100%"}} >
                                                    <img id='mainimg' className='img-fluid m-0 p-0' 
                                                    src={malzemedata.resim1=="" ? "/assets/img/default-product.jpg" : malzemedata.resim1} 
                                                    style={{ objectFit: "contain", height:"100%",width:"100%"}}
                                                    />
                                                </div>
                                                
                                                <div className='col-3 '></div>
                                            </div>
                                            <div className='col-lg-10 col-md-12 mt-4'>
                                                <div className='row'>
                                                    <div className='col-4' >
                                                        <div className='row p-0 justify-content-center border' style={{backgroundColor:'white',height:"120px",width:"100%"}} >
                                                            <img className='img-fluid m-0 p-0' 
                                                            src={malzemedata.resim1=="" ? "/assets/img/default-product.jpg" : malzemedata.resim1} 
                                                            onClick={()=>{document.getElementById("mainimg").src=malzemedata.resim1=="" ? "/assets/img/default-product.jpg" : malzemedata.resim1}}
                                                            style={{ objectFit: "contain", height:"100%",width:"100%",cursor:'pointer'}}
                                                            />
                                                        </div> 
                                                    </div>
                                                    <div className='col-4' >
                                                        <div className='row p-0 justify-content-center border' style={{backgroundColor:'white',height:"120px",width:"100%"}} >
                                                            <img  className='img-fluid m-0 p-0' 
                                                            src={malzemedata.resim2=="" ? "/assets/img/default-product.jpg" : malzemedata.resim2} 
                                                            onClick={()=>{document.getElementById("mainimg").src=malzemedata.resim2=="" ? "/assets/img/default-product.jpg" : malzemedata.resim2}}
                                                            style={{ objectFit: "contain", height:"100%",width:"100%",cursor:'pointer'}}
                                                            />
                                                        </div>  
                                                    </div>
                                                    <div className='col-4'>
                                                        <div className='row p-0 justify-content-center border' style={{backgroundColor:'white',height:"120px",width:"100%"}} >
                                                            <img  className='img-fluid m-0 p-0' 
                                                            src={malzemedata.resim3=="" ? "/assets/img/default-product.jpg" : malzemedata.resim3} 
                                                            onClick={()=>{document.getElementById("mainimg").src=malzemedata.resim3=="" ? "/assets/img/default-product.jpg" : malzemedata.resim3}}
                                                            style={{ objectFit: "contain", height:"100%",width:"100%",cursor:'pointer'}}
                                                            />
                                                        </div>  
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-7 col-md-12 align-items-center'>
                                        <div className='d-flex mt-3'>
                                            <div className='flex-grow-1 mt-3'>
                                                
                                                <h2>{malzemedata.adi}</h2>
                                                {
                                                    ayar.stokGoster=="1" 
                                                        ? (
                                                            <span><button className={malzemedata.stokDurumu==false ? 'btn btn-danger rounded border-0': 'btn btn-success rounded border-0'} 
                                                                style={{padding:"2px 2px"}}>{malzemedata.stokDurumu==false? "Stok Yok ": "Stok Var"}</button></span>
                                                        ) 
                                                        : ayar.stokGoster=="2"  ? (
                                                            <span><button className={malzemedata.stokDurumu==0 ? 'btn btn-danger rounded border-0': 'btn btn-success rounded border-0'} 
                                                                style={{padding:"2px 2px"}}>{malzemedata.stokDurumu==0 ? "Stok Yok ": "Stok Var("+malzemedata.stok+")"}</button></span>
                                                        ) : null
                                                    
                                                }
                                                
                                                <div className='mt-4 row align-middle'>
                                                    <span className='me-2 fw-500 col-auto align-middle mt-2'>Adet:</span>
                                                    <input type='number'  style={{borderRadius:"0px",maxWidth:150}} id='miktar' className='form-control col-auto' defaultValue={1}/>
                                            
                                                    <button className='btn btn-secondary col-auto' type='button' style={{borderRadius:"0px",color:'white',background:'#6c757d'}} onClick={()=>{SepeteEkle(null,false)}}>
                                                        <i className="bi bi-cart-plus fs-15" ></i>
                                                    </button> 
                                                </div>

                                                {
                                                    ayar.eczaciModulu ? 
                                                    <div>  
                                                            {malzemedata.malFazlasi>0 ? 
                                                            (<div className=' mt-4'><span className='fw-500 me-2'>Mal Fazlasi-1:</span><button style={{background:'#6c757d',minWidth:150}} className='btn btn-info col-auto border mb-1 p-1 ' onClick={()=>{SepeteEkle("1",true)}}>{malzemedata.malFazlasi} + {malzemedata.malFazlasiHediyesi}</button></div>)
                                                            : null}
                                                            {malzemedata.malFazlasi2>0 ? 
                                                            (<div className=' mt-2'><span className='fw-500 me-2'>Mal Fazlasi-2:</span><button style={{background:'#6c757d',minWidth:150}} className='btn btn-info col-auto border mb-1 p-1 ' onClick={()=>{SepeteEkle("2",true)}}>{malzemedata.malFazlasi2} + {malzemedata.malFazlasiHediyesi2}</button></div>)
                                                            : null}
                                                            {malzemedata.malFazlasi3>0 ? 
                                                            (<div className=' mt-2'><span className='fw-500 me-2'>Mal Fazlasi-3:</span><button style={{background:'#6c757d',minWidth:150}} className='btn btn-info col-auto border mb-1 p-1 ' onClick={()=>{SepeteEkle("3",true)}}>{malzemedata.malFazlasi3} + {malzemedata.malFazlasiHediyesi3}</button></div>)
                                                            : null}
                                                    </div>
                                                    :null
                                                }
                                                {
                                                    ayar.fiyatGoster ? (
                                                        <div> 
                                                        <h5 className='fw-medium mt-5'>Fiyatlandırma Detayları:</h5>
                                                        <table className='mt-4'>
                                                            <tbody className='fs-14'>
                                                                <tr>
                                                                    <td className='fw-medium'>Liste Fiyati (KDV'siz):</td>
                                                                    <td> {ConvertIntToN2(malzemedata.kdvsizFiyati)} TL</td>
                                                                </tr>  
                                                                
                                                                <tr>
                                                                    <td className='fw-medium'>Liste Fiyati (KDV'li):</td>
                                                                    <td> {ConvertIntToN2(malzemedata.kdvliFiyati)} TL</td>
                                                                </tr>  

                                                                {   ayar.eczaciModulu  ?
                                                                    <>
                                                                        <tr>
                                                                            <td className='fw-medium'>MF1 Ile Fiyati (Ozel Fiyat + İskontolu):</td>
                                                                            <td> {ConvertIntToN2(malzemedata.mf1ListeFiyati)} TL</td>
                                                                        </tr> 
    
                                                                        <tr>
                                                                            <td className='fw-medium'>MF2 Ile Fiyati(Ozel Fiyat + İskontolu):</td>
                                                                            <td> {ConvertIntToN2(malzemedata.mf2ListeFiyati)} TL</td>
                                                                        </tr> 
    
                                                                        <tr>
                                                                            <td className='fw-medium'>MF3 Ile Fiyati(Ozel Fiyat + İskontolu):</td>
                                                                            <td> {ConvertIntToN2(malzemedata.mf3ListeFiyati)} TL</td>
                                                                        </tr>
                                                                    </>: null
                                                                }

                                                                <tr>
                                                                    <td className='fw-medium'>Genel Fiyati(KDV'li + İskontolu):</td>
                                                                    <td> {ConvertIntToN2(malzemedata.listeFiyati)} TL</td>
                                                                </tr> 
                                                                <tr>
                                                                    <td className='fw-medium'>Fatura Tutarı (Ozel Fiyat,KDV'li+İskontolu) :</td> 
                                                                    <td> {ConvertIntToN2(malzemedata.faturaNet)}  TL</td>
                                                                </tr> 
                                                                {
                                                                    ayar.krediOdeme ? ( 
                                                                        <tr>
                                                                            <td className='fw-medium'>Kart ile Ödeme Fatura Tutarı (Ozel Fiyat,KDV'li+İskontolu) :</td> 
                                                                            <td> {ConvertIntToN2(malzemedata.eftKartIleFaturaNet)}  TL</td>
                                                                        </tr> 
                                                                    ) : null
                                                                } 
                                                                <tr>
                                                                    <td className='fw-medium'>Kdv Oranı:</td> 
                                                                    <td> {malzemedata.kdvoran}%</td>
                                                                </tr> 
                                                            </tbody>
                                                        </table>
                                                        </div>
                                                    ) : ""
                                                } 
                                                
                                                {
                                                    ayar.eczaciModulu ? (
                                                    
                                                        <div>
                                                            <hr className='mt-5'></hr>
                                                            <h5 className='fw-medium'>Mal Fazlası Detayları:</h5>
                                                            <div className=' mt-4 fs-14'>
                                                                <div className='row'>
                                                                    <div className='col-auto pe-0 fw-medium'>
                                                                        Mal Fazlasi 1 Ve Hediyesi:
                                                                    </div>
                                                                    <div className='col ps-1 text-left'>
                                                                        {malzemedata.malFazlasi + "-" + malzemedata.malFazlasiHediyesi}
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-auto pe-0 fw-medium'>
                                                                        Mal Fazlasi 2 Ve Hediyesi:

                                                                    </div>
                                                                    <div className='col ps-1'>
                                                                        {malzemedata.malFazlasi2 + "-" + malzemedata.malFazlasiHediyesi2} 
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-auto pe-0 fw-medium'>
                                                                        Mal Fazlasi 3 Ve Hediyesi:

                                                                    </div>
                                                                    <div className='col ps-1'>
                                                                        {malzemedata.malFazlasi3 + "-" + malzemedata.malFazlasiHediyesi3} 
                                                                    </div>
                                                                </div> 
                                                            </div> 
                                                        </div>
                                                    ):null
                                                }
                                                <div>
                                                    <hr className='mt-4'></hr>
                                                    <h5 className='fw-medium'>Malzeme Barkodları:</h5>
                                                    <div className=' mt-4 fs-14'>
                                                        {
                                                            malzemedata.barkodlar.map((item,index)=>{ 
                                                                return (
                                                                    <div className='row' key={item}>
                                                                        <div className='col-auto pe-0 fw-medium'>
                                                                            Barkod-{index+1}:
                                                                        </div>
                                                                        <div className='col ps-1 text-left'>
                                                                            {item}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    ayar.iskGoster ? (
                                                        <div>
                                                            <hr className='mt-4'></hr>
                                                            <h5 className='fw-medium'>İndirim Detayları:</h5>
                                                            <div className=' mt-4 fs-14'>
                                                                <div className='row'>
                                                                    <div className='col-auto pe-0 fw-medium'>
                                                                        1.İndirim:
                                                                    </div>
                                                                    <div className='col ps-1 text-left'>
                                                                        {malzemedata.isk1}%
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-auto pe-0 fw-medium'>
                                                                        2.İndirim:
                                                                    </div>
                                                                    <div className='col ps-1'>
                                                                        {malzemedata.isk2}%
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-auto pe-0 fw-medium'>
                                                                        3.İndirim:
                                                                    </div>
                                                                    <div className='col ps-1'>
                                                                        {malzemedata.isk3}%
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-auto pe-0 fw-medium'>
                                                                        4.İndirim:
                                                                    </div>
                                                                    <div className='col ps-1'>
                                                                        {malzemedata.isk4}%
                                                                    </div>
                                                                </div> 
                                                                {
                                                                    ayar.krediOdeme ? ( 
                                                                        <div className='row'>
                                                                            <div className='col-auto pe-0 fw-medium'>
                                                                                Kart İle Ödeme İndirim Oranı:
                                                                            </div>
                                                                            <div className='col ps-1'>
                                                                                {malzemedata.eftKartIndirimOrani}%
                                                                            </div>
                                                                        </div>
                                                                    ):null
                                                                }
                                                                
                                                            </div> 
                                                        </div>
                                                    ):""
                                                }
                                                <hr className='mt-4'></hr>

                                                <p className=' fs-14'>
                                                    <span className='fw-medium'>Aciklama:</span><br/>
                                                    {malzemedata.aciklama}
                                                </p>
                                                <hr className='mt-4'></hr>
                                                <table>
                                                    <tbody className='fs-14'>
                                                        <tr>
                                                            <td className='fw-medium'>Kategori      :</td>
                                                            <td>{malzemedata.kategori}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='fw-medium'>Alt Kategori  : </td>
                                                            <td>{malzemedata.altKategori}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='fw-medium'>Ana Birim     :</td>
                                                            <td>{malzemedata.birim}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                     )  : ""}
                </div>
            </div>
            

            
        </div>
    )
     
}
 