import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import {Link,useNavigate,useParams } from 'react-router-dom'
import axiosInstance from '../../api/api';
import Header from '../../components/Header';
import { DataGrid } from 'devextreme-react';
import { Column, Paging } from 'devextreme-react/data-grid';
import { forEach } from 'lodash';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import { HttpStatusCode } from 'axios';
import { toast } from 'react-toastify';
import { Pager } from 'devextreme-react/tree-list';

export default function Siparisler(){
    const [datalist,setdatalist] = useState(null);
    const [filtrdata,setfiltrdata] = useState(null);
    const dataGridRef = useRef(null);
    const [search,setSearch] = useState(null);
    const [startdate,setStartdate] = useState(null);
    const [enddate,setEnddate] = useState(null);
    const [statusdata,setStatusdata] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();
     

    useEffect(()=>{ 
        const fetchdata = async() =>{
            let res = await axiosInstance.get("Siparis/GetSiparisler");
            if(res != null && res.status==200){
                setfiltrdata(res.data.data);
                setdatalist(res.data.data);
            }
            
        }

        fetchdata();
 
        const handleResize = () => {
          setIsMobile(window.innerWidth < 768);  
      };
   
      handleResize(); 
      window.addEventListener('resize', handleResize);
   
      return () => {
          window.removeEventListener('resize', handleResize);
      };
    },[]);

    const SiparisDurumGuncelle = async(id) =>{

        var clone = [...filtrdata];
        var clone2 = [...datalist];
        var durumcsonuc = 0; 

        for (let index = 0; index < filtrdata.length; index++) {
            const element = filtrdata[index];
            durumcsonuc = element.durumu;
            if (element.siparisId == id){
              var res = await axiosInstance.put("Siparis/UpdateSiparisDurum/"+id);
              if(res.status != HttpStatusCode.Ok){
                
                  toast.error(res.response.data)

              } 
              else{
                toast.success(res.data.data == 0 ? "İptal Etme İşlemi Geri Alındı" : "İptal Etme İşlemi Başarılı")

                element.durumu = res.data.data;
                durumcsonuc = res.data.data;

                clone[index] = element;

                setfiltrdata(clone);
              }
            }
        }

        for (let index = 0; index < datalist.length; index++) {
            const element = datalist[index];
            durumcsonuc = element.durumu;
            if (element.siparisId == id){
                    element.durumu = durumcsonuc;

                    clone2[index] = element;

                    setdatalist(clone2);
            }
        }

    }   

    const onCellPrepared = (e) => {
        
        if(e.data!=null ){
            if(e.data.durumu =="1" && e.rowType === "data"){
                e.cellElement.classList.add("canceled");
            }
        }
        else{
            e.cellElement.style.borderBottom = "1px solid #DFDFDF";
        }
    };

    const filtreUygula = () => {
        let filtrdataclone = [...datalist];
    
        if (startdate != null) {
          filtrdataclone = filtrdataclone.filter((x) => new Date(x.tarih.toString()) >= startdate);
        }
    
        if (enddate != null) {
          filtrdataclone = filtrdataclone.filter((x) => new Date(x.tarih.toString()) <= enddate);
        }
    
        if (statusdata == 0 || statusdata == 1) {
          filtrdataclone = filtrdataclone.filter((x) => x.durumu.toString() == statusdata.toString());
        }
    
        if (search != null) {
          const upperSearch = search.trim().toUpperCase();
          filtrdataclone = filtrdataclone.filter(
            (p) =>
              p.aciklama.toString().toUpperCase().includes(upperSearch) ||
              p.belgeNo.toString().toUpperCase().includes(upperSearch) ||
              p.cariAdi.toString().toUpperCase().includes(upperSearch) ||
              p.sevkDurumu.toString().toUpperCase().includes(upperSearch) ||
              p.kdv.toString().toUpperCase().includes(upperSearch) ||
              p.genelToplam.toString().toUpperCase().includes(upperSearch) ||
              p.no.toString().toUpperCase().includes(upperSearch) ||
              p.cariKodu.toString().toUpperCase().includes(upperSearch)
          );
        }
        setfiltrdata(filtrdataclone);
    };

    const onExportButtonClick = () => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
    
        exportDataGrid({
          component: dataGridRef.current.instance,
          worksheet: worksheet
        }).then(function() {
          workbook.xlsx.writeBuffer().then(function(buffer) {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Siparişler.xlsx');
          });
        });
    };

    const SiparisEkle = () =>{
        navigate("/Magaza"); 
    }

    function onCustomizeColumns(columns){  
      for(var i = 0; i < columns.length; i++) {
        columns[i].cssClass = 'table-current-list-td';  
      } 
    }

    const ConvertIntToN2 = (number) =>{
      if(number!=null){

        const str = number.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
        return str;
      }else{
        return null;
      }
    }

    function formatTarih(tarih) {
        const gun = String(tarih.getDate()).padStart(2, '0');
        const ay = String(tarih.getMonth() + 1).padStart(2, '0');
        const yil = tarih.getFullYear();
        const saat = String(tarih.getHours()).padStart(2, '0');
        const dakika = String(tarih.getMinutes()).padStart(2, '0');
      
        return `${gun}/${ay}/${yil} ${saat}:${dakika}`;
    }
    return (
        <div>
      {/*----------Page Content Header---------*/}
      <Header anadizin="Anasayfa" baslik="Siparişler" detaydizin="Siparişler" anadizinyol="/" />

        {/*----------Page Content Body---------*/}
        <div className="row page-content-wrapper">
          <div className="col-lg-12">
            <div className="card" id="customerList">
              <div className="card-header border-bottom-dashed">
                <div className="row g-4 align-items-center justify-content-center ">
                  <div className="col">
                    <div>
                      <h5 className="card-title mb-0 text-center text-sm-start">Siparişlerin Listesi</h5>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="d-flex flex-wrap align-items-start gap-2">
                        <button type="button" className="btn btn-success add-btn" onClick={SiparisEkle} data-bs-toggle="modal" id="create-btn" data-bs-target="#showModal">
                            <i className="ri-add-line align-bottom me-1" /> Sipariş Ekle</button>
                        <button type="button" className="btn btn-info" onClick={onExportButtonClick}>
                            <i className="ri-file-download-line align-bottom me-1" /> Dışarı Aktar</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body border-bottom-dashed border-bottom">
                <form>
                  <div className="row g-3">
                    <div className="col-xl-6">
                      <div className="search-box">
                        <input type="text" className="form-control search"  onChange={(e)=>{setSearch(e.target.value)}} placeholder="Aramak İstediğiniz Sipariş İle İlgili Bir Kelime Giriniz." />
                        <i className="ri-search-line search-icon"/>
                      </div>
                    </div>
                    {/*end col*/}
                    <div className="col-xl-6">
                      <div className="row g-3">
                        <div className="col-sm-3">
                          <div >
                            <div className="form-group">
                              <div className="input-group date fs-13" id="datetimepicker1">
                                <input type="date" className="form-control fs-13" onChange={(e)=>{setStartdate(new Date(e.target.value.toString()))}} />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*end col*/}
                        <div className="col-sm-3">
                          <div >
                            <div className="form-group">
                              <div className="input-group date fs-13" id="datetimepicker1" >
                                <input type="date" className="form-control fs-13"  onChange={(e)=>{setEnddate(new Date(e.target.value.toString()))}}/>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*end col*/}
                        <div className="col-sm-3">
                          <div >
                            <div className="form-group">
                              <select  className="form-select py-2 px-3 fs-13" onChange={(e)=>{setStatusdata(e.target.value)}}>
                                <option title="Tüm" value={2}>Tüm</option>
                                <option title="Geçerli" value={0}>Geçerli</option>
                                <option title="İptal Edildi" value={1}>İptal Edildi</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        {/*end col*/}
                        <div className="col-sm-3">
                          <div>
                            <button type="button" className="btn btn-primary w-100" onClick={filtreUygula}> <i className="ri-equalizer-fill me-2 align-bottom" />Filtrele</button>
                          </div>
                        </div>
                        {/*end col*/}
                      </div>
                    </div>
                  </div>
                  {/*end row*/}
                </form>
              </div>
              <div className="card-body p-0">
                    <div className='c'>
                        <DataGrid className='table-product-list fw-400 leftResize'  dataSource={filtrdata}
                                allowColumnReordering={true}
                                rowAlternationEnabled={true}
                                showBorders={true}      
                                onCellPrepared={onCellPrepared}
                                customizeColumns={onCustomizeColumns}
                                columnHidingEnabled={true}
                                ref={dataGridRef} style={{width:"100%"}}>
                                 
                                 <Pager  showInfo={true} allowedPageSizes={[20,50,100]} showPageSizeSelector={true} visible={true}  showNavigationButtons="compact"/>

                            <Paging defaultPageSize={20} /> 
                            <Column width={120} dataField='Aksiyon'  alignment='center' cellRender={data=>{
                                var element = data.data;
                                return (
                                    <div className="row">

                                      {
                                        element.belgeNo == "1" && element.ozelkod == "0" ? 
                                          (
                                            <div className="col-3 offset-1" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Kredi Kartı İle Ödeme">
                                           
                                              <Link to={"/Odeme/"+element.siparisId} className="text-primary d-inline-block">
                                                  <i className="bi bi-credit-card fs-16" />
                                              </Link>
                                              
                                            </div>
                                          )
                                        : (<div className="col-3 offset-1"></div>) 
                                    }

                                      <div className="col-3">
                                          <Link to={"/Siparis/"+element.siparisId} className="text-primary d-inline-block">
                                              <i className="bi bi-eye-fill fs-16" />
                                          </Link>
                                      </div>

                                      <div className="col-3">
                                          <button onClick={()=>SiparisDurumGuncelle(element.siparisId)} className={"p-0 text-"+(element.durumu==0 ? "danger" : "info")+"  d-inline-block" }  style={{border: 0, backgroundColor: 'transparent'}} data-bs-toggle="modal">
                                              <i className={"bi "+(element.durumu ==0 ? "bi-x-circle-fill fs-13" : "bi-bootstrap-reboot fs-15") }/>
                                          </button>
                                      </div>
                                  </div>
                                    
                                )
                            }}/> 
                            
                            <Column width={130}  dataField='tarih' dataType='datetime' format={(date) => {
                              
                              return formatTarih(new Date(date));
                            }} alignment='center'/>   

                            <Column width={140} dataField='no' caption='No' alignment='center' /> 
                              
                            <Column dataField="aciklama" width={500}  alignment='center'  cellRender={data=>{
                                let element = data.data;
                                return( 
                                  <span style={{whiteSpace:'pre-wrap'}}>
                                    {element.aciklama} 
                                  </span>
                                    
                                );
                            }}/>
  
 
                            <Column width={150}  dataField='net' alignment='center'  cellRender={(render)=>{
                                    return(
                                        <span className='text-right '>{ConvertIntToN2( render.data.net)}</span>
                                    )
                                }}
                            /> 
                                    
                            <Column width={150}  dataField='kdv'alignment='center' cellRender={(render)=>{
                                                return(
                                                    <span>{ConvertIntToN2( render.data.kdv)}</span>
                                                )
                                            }}
                                    /> 
                            <Column width={150}  dataField='genelToplam'  alignment='center' cellRender={(render)=>{
                                                return(
                                                    <span>{ConvertIntToN2( render.data.genelToplam)}</span>
                                                )
                                            }}
                                    /> 
                            

                            <Column width={100}  dataField='sevkDurumu' alignment='center' cellRender={data=>{
                                var element = data.data;
                                return (
                                    <span className={"badge "+(element.gerceklesmeDurumu==0 ? "badge-soft-secondary text-uppercase" :
                                        element.gerceklesmeDurumu ==1 ? "badge-soft-success text-uppercase" :
                                        element.gerceklesmeDurumu ==2 ? "badge-soft-info text-uppercase" :
                                        element.gerceklesmeDurumu ==3 ? "badge-soft-danger text-uppercase" : ""
                                    )}>{element.sevkDurumu}</span>
                                )
                            }}/> 
                            
                        </DataGrid>
                    </div> 
              </div>
            </div>
          </div>
          {/*end col*/}
        </div>

        </div>
    )
}