import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import axiosInstance from '../../api/api'
import { toast } from 'react-toastify'; 
import { useNavigate } from 'react-router-dom/dist';

export default function SifreDegistir  () { 
  const [sifreler,setSifre] = useState({
      eski:null,
      yeni:null,
      yeniden:null
  });
  
  const navigate = useNavigate();

  const InputHandle = (e) =>{
      let clone = {...sifreler};

      var name = e.target.name;
      var value = e.target.value;

      clone[name] = value;

      setSifre(clone);
      
  } 

  const Kaydet = () =>{ 
      if(sifreler.eski==null || sifreler.yeni==null || sifreler.yeniden==null ){
        toast.error("Boş alan bırakmayınız.") 
      }
      else if(sifreler.yeni.toString().lenght<6 || sifreler.yeniden.toString().lenght<6 ){
        toast.error("Şifreler en az 6 karakter olmalı.")
      }
      else if(sifreler.yeni != sifreler.yeniden){
        toast.error("Yeni Şifreleriniz Uyuşmuyor.")
      }
      else{
        const fetchdata = async() =>{
            let res = await axiosInstance.put("Erp/UpdateSifre",{"yenisifre":sifreler.yeni,"eskisifre":sifreler.eski })   
            
            if(res!=null){
                if(res.status == 200){
                  toast.success("Şifre Başarıyla Değiştirildi.")
                  navigate("/");
                }
                else{ 
                    toast.error(res.response.data) 
                }
            } 
        }
        fetchdata(); 
      } 
  }

  return (
      <div>

        {/*----------Page Content Header---------*/}
        <Header anadizin="Anasayfa" baslik="Şifre Değiştir" detaydizin="ŞifreDeğiştir" anadizinyol="index/" />


          {/*----------Page Content Body---------*/}
          <div className="d-flex flex-row page-content-wrapper">
            <div className="card col-12">
              <div className="card-header">
                <h6 className="card-title ">Şifre Değiştir</h6>
              </div>
              <div className="card-body">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group row mb-3">
                    <label htmlFor="oldpassword" className="col-sm-2 col-form-label">Eski Şifre</label>
                    <div className="col-sm-10">
                      <input type="password" className="form-control"  name='eski' placeholder="Eski şifreyi tekrardan giriniz." onChange={InputHandle}/>
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <label htmlFor="newpassword" className="col-sm-2 col-form-label">Şifre</label>
                    <div className="col-sm-10">
                      <input type="password" className="form-control"  name='yeni' placeholder="Yeni şifreyi giriniz." onChange={InputHandle} />
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <label htmlFor="againpassword" className="col-sm-2 col-form-label">Tekrar Şifre</label>
                    <div className="col-sm-10">
                      <input type="password" className="form-control"  name='yeniden' placeholder="Yeni şifreyi tekrardan giriniz."  onChange={InputHandle}/>
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-3 col-12 justify-content-end">
                  <div className="col-sm-auto justify-content-end">
                    <button className="btn btn-primary col-auto float-end" onClick={Kaydet}>Kaydet</button>
                  </div>
                </div>
              </div>
            </div>
          </div>


      </div>
  )
}
 