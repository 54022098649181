import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'; 
import Header from '../../components/Header';


export default function Sonuc () {
  const {durum,mesaj,id} = useParams();
  const [sonucmesaj,setSonucMesaj] = useState("");
  const [sinif,setsinif] =useState("");

  useState(()=>{
    if(durum=="Success"){
      setsinif("alert alert-success");
      setSonucMesaj("Ödeme İşlemi Başarıyla Tamamlandı.")
    }
    else{
      setsinif("alert alert-danger");
      setSonucMesaj("Ödeme İşlemi Başarısız."+"Hata:"+mesaj)
    }
  },[durum]);

  return (
    <div> 
      {/*----------Page Content Header---------*/}
        <Header anadizin="Anasayfa" baslik="Ödeme Sonucu" detaydizin="Sonuç" anadizinyol="/" />
        
        <div className={sinif} role="alert">
            {sonucmesaj}
            <Link className='btn ' to={"/Siparis/"+id}><span className='fw-500'>Ödeme Detayı veya Makbuz için tıklayınız.</span></Link>
        </div>
    </div>
  )
} 