import MainLayout from './components/MainLayout'; 
import { BrowserRouter,  Route, Routes } from 'react-router-dom';
import 'devextreme/dist/css/dx.light.css';   
import Malzemeler from './pages/Malzeme/Malzemeler';
import MalzemeDetay from './pages/Malzeme/MalzemeDetay';  
import Musteriler from './pages/Musteriler/Musteriler'; 
import MusteriEkstre from './pages/Musteriler/MusteriEkstre'; 
import Sepet from './pages/Sepet/Sepet';
import SiparisTamamla from './pages/Sepet/SiparisTamamla';
import Siparisler from './pages/Siparis/Siparisler';
import SiparisDetay from './pages/Siparis/SiparisDetay';
import Sonuc from './pages/Siparis/Sonuc';
import TahsilatGoruntule from './pages/Siparis/TahsilatGoruntule';
import Odeme from './pages/Siparis/Odeme';
import SifreDegistir from './pages/Auth/SifreDegistir';
import Faturalar from './pages/Faturalar/Faturalar';
import FaturaGoruntule from './pages/Faturalar/FaturaGoruntule';
import NotFound from './pages/Error/NotFound';
import ServerError from './pages/Error/ServerError';
import Home from './pages/Giris/Home';
import Login from './pages/Auth/Login';

function App() {
     
    return(
    <>
        <BrowserRouter forceRefresh={true}>
            <Routes> 
                <Route Component={MainLayout} >
                    <Route path='/' index Component={Home} />
                    <Route path='/magaza' Component={Malzemeler} />
                    <Route path='/magaza/:id' Component={MalzemeDetay} />
                    <Route path='/musteriler' Component={Musteriler} />
                    <Route path='/musteriEkstre/:id' Component={MusteriEkstre} />

                    <Route path='/sepet' Component={Sepet} />
                    <Route path='/siparisTamamla' Component={SiparisTamamla} />
                    <Route path='/siparisler/' Component={Siparisler} />
                    <Route path='/siparis/:id' Component={SiparisDetay} />
                    <Route path='/sonuc/:id/:durum/:mesaj' Component={Sonuc} />
                    <Route path='/odeme/:id' Component={Odeme} />
                    <Route path='/faturalar/' Component={Faturalar} />
                    <Route path='/sifredegistir' Component={SifreDegistir} />  
                </Route> 

                <Route>
                    <Route path='/tahsilatgoruntule/:id' Component={TahsilatGoruntule} />  
                    <Route path='/faturaGoruntule/:id' Component={FaturaGoruntule} /> 
                    <Route path='/login' Component={Login} /> 
                    <Route path='*' Component={NotFound} /> 
                    <Route path='/serverError' Component={ServerError} /> 
                </Route> 
            </Routes>
        </BrowserRouter>
    </>
    
    );
}


export default App; 