import React, { useEffect, useRef, useState } from 'react'
import { Link,  useParams } from 'react-router-dom'
import axiosInstance from '../../api/api';
import { useReactToPrint } from 'react-to-print';

export default function TahsilatGoruntule() {
    var {id} = useParams(); 
    const componentRef = useRef();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true); 
    const [data, setdata] = useState(null);   


    useEffect(()=>{
        const fetchdata = async() =>{
            var resp = await axiosInstance.get("Siparis/GetTahsilat/"+id); 
            setLoading(true);
            
            if(resp!=null ){
                if(resp.status == 200){
                    if(resp.data.hata){
                        setError(resp.data.mesaj);
                    }
                    else{ 
                        let date = new Date(resp.data.data.tarih);
                        let year = date.getFullYear();
                        let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                        let day = String(date.getDate()).padStart(2, '0');
                        let hours = String(date.getHours()).padStart(2, '0');
                        let minutes = String(date.getMinutes()).padStart(2, '0');
                        let seconds = String(date.getSeconds()).padStart(2, '0');
                        
                        let formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
                        resp.data.data.tarih = formattedDateTime;
    
                        setdata(resp.data.data) 
                    }
                }
                else{
                    setError(resp.response.data);
                }
            }
            
            setLoading(false);
        }

        fetchdata();
    },[]);
 
    const Yazdir = useReactToPrint({
        content: () => componentRef.current,
      });
      
    return (
        <div style={{width:"100%"}}>
            <div className='navbar bg-dark mb-2'>
                <div className='container' style={{margin:"auto"}}>
                    <div className='d-flex justify-content-end w-100'>
                        <div className='col-auto me-1'>
                        <Link to="/" className='btn bg-white text-dark b-0 '  >Anasayfa</Link>

                        </div>
                        <div className='col-auto p-0 m-0'>
                            <button className='btn bg-white text-dark b-0' onClick={()=>{Yazdir()}}>Yazdir</button> 
                        </div>
                    </div>
                </div>
            </div>
            <div className='container' style={{margin:"auto"}}>
                {loading &&  <p>Loading...</p>}
                {error && <span style={{ color: 'red' }}>{error}</span>}
                {(data !=null ) && (
                    
                    <div className="receipt text-center" id='myIframe'>
                        <div ref={componentRef} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <div className="receipt-header">
                                <h2>Tahsilat Makbuzu</h2>
                            </div>
        
                            <div className="receipt-details">
                                <div className='mt-4 ' style={{margin:"0 auto"}}>
                                    <b>Kart Numarası:</b> {data.kartNo}
                                </div>
                                <div>
                                    <strong>Kart Adı:</strong> {data.kartSahibi}
                                </div>
                                <div>
                                    <strong>Toplam Tutar:</strong> {data.odemeTutari} TL
                                </div>

                                <hr/> 
                                <div>
                                    <strong>İşlem Zamanı:</strong> {data.tarih} 
                                </div> 
                                <div>
                                    <strong>İşlem Yapılan Ip Adresi:</strong> {data.ipAdresi} 
                                </div>
                                <div>
                                    <strong>Ödeme Güvenlik Türü:</strong> 3DPAY
                                </div>
                                <div>
                                    <strong>Ödeme Yapılan Firma:</strong> {data.sirketAdi}
                                </div>
                            </div> 
                        </div>
                </div>
                )}    

                
            </div>
        </div>
    )
}
 