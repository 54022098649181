import { useState ,useEffect} from 'react'
import Header from '../../components/Header'
import axiosInstance from '../../api/api';
import { Link, useNavigate } from 'react-router-dom'; 
import { toast } from 'react-toastify';
 
export default function Sepet() {
    const [sepeturunler,setSepeturunler] = useState([]);
    const [sepetaciklama,setSepetaciklama] = useState("");
    const [state,setState] = useState({aratoplam:0.0,kdvtoplam:0.0,toplam:0.0,indirim:0.0,mfindirim:0.0});
    const [loading, setLoading] = useState(true); 
    const loopArray = Array.from({ length: 6 }, (_, index) => index);
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
 
    const [ayar,setAyar] = useState({
        stokGoster: "0",
        fiyatGoster: true,
        iskGoster: true,
        b2bAmbarKodu: "-1",
        negatifTumAmbarKontrol: true,
        negatifStokSiparisKayit: false,
        fiyatSira: "-1",
        acikHesapOdeme: true,
        krediOdeme: true,
        eczaciModulu: false,
        altKategoriGozuksun: true,
        kategoriGozuksun: true,
        kdvliFiyatGozuksun: true,
        kdvsizFiyatGozuksun: true,
        mfAktarimTipi: "0"
      });  

    useEffect(()=>{ 
        setLoading(true);   
  
        const fetchdata = async() =>{  
            const res= await axiosInstance.get("Erp/GetUygulamaAyarlari");
            setAyar(res.data); 
            const res2 = await axiosInstance.get("Sepet/GetSepet")
  
            if(res2!=null && res2.status ==200 && res2.data.data!=null){  
                setSepeturunler(res2.data.data.detaylar); 
                setSepetaciklama(res2.data.data.aciklama)   
            }  
             
        }  
        fetchdata();  
        setLoading(false); 

        
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768); 
      };
   
      handleResize(); 
      window.addEventListener('resize', handleResize);
   
      return () => {
        window.removeEventListener('resize', handleResize);
      };
  
    },[]); 

    useEffect(() => {  
        updatePrices();
    }, [sepeturunler]);

    /* Hesap Alanı */ 
    const updatePrices = () => {
        var aratoplamcalc = 0;
        var indirimcalc = 0;
        var kdvcalc = 0;
        var faturaNetcalc = 0;
        var mfindirimcalc = 0;

         for (let index = 0; index < sepeturunler.length; index++) { 
            const element = sepeturunler[index];
            var miktar  = 0; 
            
            if(element.mfAktif){
                if(element.mfSira=="1"){
                    miktar += (element.malFazlasi + element.malFazlasiHediyesi) 
                    kdvcalc += element.kdvTutari * element.malFazlasi; 
                    indirimcalc += element.iskTutari * element.malFazlasi;  

                    mfindirimcalc += element.mfIskTutari *  element.malFazlasi;
                }
                else if(element.mfSira=="2"){
                    miktar += (element.malFazlasi2 + element.malFazlasiHediyesi2) 
                    kdvcalc += element.kdvTutari * element.malFazlasi2;   
                    indirimcalc += element.iskTutari * element.malFazlasi2;  

                    mfindirimcalc += element.mfIskTutari *  element.malFazlasi2;
                }
                else if(element.mfSira=="3"){
                    miktar += (element.malFazlasi3 + element.malFazlasiHediyesi3) 
                    kdvcalc += element.kdvTutari * element.malFazlasi3; 
                    indirimcalc += element.iskTutari * element.malFazlasi3;  

                    mfindirimcalc += element.mfIskTutari *  element.malFazlasi3;
                }


                aratoplamcalc += element.birimFiyati * miktar;   
                faturaNetcalc += element.mfIleFaturaNet * miktar;  

            }
            else{ 
                miktar = element.miktar;
                faturaNetcalc += element.faturaNet * miktar;
                aratoplamcalc += element.birimFiyati * miktar;  
                kdvcalc += element.kdvTutari * miktar; 
                indirimcalc += element.iskTutari * miktar;  
                mfindirimcalc += 0;  
            } 
            
         }
         setState({aratoplam:ConvertIntToN2(aratoplamcalc),kdvtoplam:ConvertIntToN2(kdvcalc),toplam:faturaNetcalc,indirim:ConvertIntToN2(indirimcalc),mfindirim:ConvertIntToN2(mfindirimcalc)}); 

    };  

    const AdetGuncelle = (tip, model,yeniMiktar=0) => {
        
        if(tip==2 && model.miktar-1 <0 ){
            toast.error("Miktar 0 dan küçük olamaz");
        }else{

            const requestDto = {
                Tip: tip,
                SepetDetayId: model.id,
                YeniMiktar: yeniMiktar ?? 0
            };
    
            const updatedata = async() =>{ 
                
                const res = await axiosInstance.put("Sepet/UpdateSepetItem",requestDto) 
                if(res!=null){

                    if(res.status==200){  
                        setSepeturunler(res.data.data.detaylar);  
                        setSepetaciklama(res.data.data.aciklama)    
                        toast.success("Başarıyla Güncellendi");
                    }
                    else{
                        toast.error(res.response.data); 
                    }

                }
            }
    
            updatedata();
        }
    };

    const UrunKaldir = (Id) =>{ 
        const deletedata = async() =>{ 
            const res = await axiosInstance.delete("Sepet/DeleteSepetItem/"+Id)

            if(res!=null && res.status==200){  
                setSepeturunler(res.data.data.detaylar); 
                setSepetaciklama(res.data.data.aciklama); 

                if(Id==0){
                    toast.success("Başarıyla Temizlendi.");

                }else{
                    toast.success("Başarıyla Kaldırıldı."); 
                }

            } 
        }

        deletedata();
    }
  
    const Tamamla = () =>{
        const updateaciklama = async() =>{
            const res = await axiosInstance.put("Sepet/UpdateSepetAciklama",{Aciklama:sepetaciklama})
            if(res !=null){
                if(res.status==200){
                    navigate("/SiparisTamamla"); 
                }
                else{
                    toast.error("Hata sepet aciklaması düzeltilemedi.");
                }
            } 
        }
        updateaciklama(); 
    }

    const ConvertIntToN2 = (number) =>{
        if(number!=null){
      
          const str = number.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
          return str;
        }else{
          return null;
        }
    } 
    
     
    return (
        <div>
            {/*----------Page Content Header---------*/}
            <Header anadizin="Anasayfa" baslik="Sepet" detaydizin="Sepet" anadizinyol="/"></Header>
        
            {/*----------Page Content Body---------*/}
            <div className="row mb-3 page-content-wrapper"> 
                <div className="col-xl-8"> 
                    <>
                        <div className="row align-items-center gy-3 mb-3">
                            <div className="col-sm">
                                <div>
                                <p className="fs-14 mb-0">Sepette {sepeturunler!=null ? sepeturunler.length : 0} Adet Ürün Bulunmaktadır.</p>
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <Link to="/Magaza" className="link-primary text-decoration-underline">Alışverişe Devam Et</Link> 
                            </div>
                        </div> 
                        {
                            sepeturunler.map((urun,index) =>{
                                let mf;
                                let mfhediyesi;


                                if (urun.mfAktif) {
                                    if (urun.mfSira === "1") {
                                        mf = urun.malFazlasi; 
                                    } else if (urun.mfSira === "2") {
                                        mf = urun.malFazlasi2; 
                                    } else if (urun.mfSira === "3") {
                                        mf = urun.malFazlasi3; 
                                    }
                                }  
 
                                return (
                                    
                                    <div key={index}>

                                        <div className="card product" >
                                            <div className="card-body p-3">
                                                <div className="row  ">
                                                    <div className='col-lg-4 mb-0 d-flex col-12'>
                                                        
                                                        <div className="mb-2" width="120px">
                                                            <div className="avatar-lg bg-light rounded p-0">
                                                                <img src={urun.resim1 == '' ? "/assets/img/default-product.jpg" : urun.resim1} style={{objectFit:'contain',width:'100%',height:'100%'}}>

                                                                </img>
                                                            </div>
                                                        </div>
                                                        <div className=" ps-2 mb-2 align-items-center" style={{width:'calc(100%+ 100px)'}}>
                                                            <h5 className="fs-14 " ><Link className="" to={`/Magaza/`+urun.malzemeId} >{urun.adi}</Link> <a href="#" className="text-dark"></a></h5>
                                                            <ul className="list-inline text-muted">
                                                                <li className="list-inline-item">Kategori : <span className="fw-medium">{urun.kategori}</span></li>
                                                            </ul> 
                                                                {
                                                                    urun.mfAktif ? 
                                                                        (
                                                                        <>
                                                                            <div className='mt-1'> 
                                                                                <span >Mf Uygulaması:</span> 
                                                                                <span className='fw-500'>
                                                                                    {
                                                                                        urun.mfSira=="1" ? (urun.malFazlasi.toString() +"+"+ urun.malFazlasiHediyesi.toString()): 
                                                                                        urun.mfSira=="2" ? (urun.malFazlasi2.toString() +"+"+ urun.malFazlasiHediyesi2.toString()) : 
                                                                                        urun.mfSira=="3" ? (urun.malFazlasi3.toString() +"+"+ urun.malFazlasiHediyesi3.toString()) : 
                                                                                        null
                                                                                    } 
                                                                                </span>

                                                                            </div>
                                                                            <div>
                                                                                <span>Adet:</span>
                                                                                <span className='fw-500'>
                                                                                    {
                                                                                        urun.mfSira=="1" ? (ayar.mfAktarimTipi=="0" ? (urun.malFazlasi.toString()): (urun.malFazlasi+urun.malFazlasiHediyesi))
                                                                                        : urun.mfSira=="2" ? (ayar.mfAktarimTipi=="0" ? (urun.malFazlasi2.toString()): (urun.malFazlasi+urun.malFazlasiHediyesi2)) 
                                                                                        : urun.mfSira=="3" ? (ayar.mfAktarimTipi=="0" ? (urun.malFazlasi3.toString()): (urun.malFazlasi3+urun.malFazlasiHediyesi3))
                                                                                        :null
                                                                                    } 
                                                                                </span>
                                                                            </div>
                                                                            </>
                                                                        )
                                                                    :   
                                                                    <div className="input-step mt-1">

                                                                        <button type="button" className="minus" onClick={()=>AdetGuncelle(2,urun)}>–</button>

                                                                        <input
                                                                        type="text" 
                                                                        className="product-quantity"
                                                                        value={parseFloat(urun.miktar)}  
                                                                        onChange={(e)=>{
                                                                            const yeniMiktar = parseFloat(e.target.value); 
                                                                            AdetGuncelle(3, urun,yeniMiktar);  
                                                                        }} 
                                                                        />
                                                                        <button type="button" className="plus" onClick={()=>AdetGuncelle(1,urun)}>+</button> 

                                                                    </div>
                                                                }
                                                                
                                                            
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-8 col-md-12 row p-0 m-0 '>
                                                        {
                                                            ayar.iskGoster ? (
                                                                
                                                                <div className='col-lg-10  col-md-12  justify-content-start row mb-2' >
                                                                    {/* Dizi üzerinde dönerek her bir öğeyi işleyin */}
                                                                    {loopArray.map((item, index) => 
                                                                        {if(index + 1 == 5 && (ayar.eczaciModulu==false || ayar.mfAktarimTipi == "0")){
                                                                            
                                                                        }
                                                                        else if(index+1 == 6 && ayar.krediOdeme==false){

                                                                        } 
                                                                        else{
                                                                            return(
                                                                            
                                                                                <div className='col-4 col-md-auto  mb-4 text-center' style={{minWidth:50}} key={index} >
                                                                                    <p className='fw-medium text-muted fs-13 mb-2'>{index+1 == 6 ? "EFT İndirimi" : index+1 == 5 ? "MF İndirimi"   
                                                                                    : "İndirim"+(index+1)}
                                                                                    </p>
                                                                                    <span >
                                                                                        {index+1 == 1 ? urun.isk1 : index+1==2 ? urun.isk2 : index+1==3 ? urun.isk3 : index+1==4 ? urun.isk4 
                                                                                            : index+1==5 ? ConvertIntToN2(urun.isk5): index+1==6 ? urun.isk6 :""}%
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                        }
                                                                    )}
                                                                
                                                                </div>
                                                            ) : ""
                                                        }
                                                        <div className={  ayar.iskGoster ?  "col-lg-2 col-md-12  p-0 m-0 mb-2" :"col-lg-12 col-md-12 mb-2" } >
                                                            <div className="text-lg-end">
                                                            <p className=" fw-medium  text-muted mb-1"> Ürün Fiyatı:</p>
                                                                {
                                                                    urun.listeFiyati != urun.faturaNet 
                                                                    ? 
                                                                    <div className='clearfix'>
                                                                        <h5 className="fs-14" style={{textDecoration:"line-through"}}><span id="ticket_price" className="product-price">{
                                                                        
                                                                        ConvertIntToN2(urun.listeFiyati)}</span> TL</h5> 
                                                                        <h4 className="fs-14"><span id="ticket_price" className="product-price">{ayar.mfAktarimTipi=="1" 
                                                                        && urun.mfAktif ?  ConvertIntToN2(urun.mfIleFaturaNet) : ConvertIntToN2(urun.faturaNet)}</span> TL</h4> 
                                                                    </div>
                                                                        : <h5 className="fs-14"><span id="ticket_price" className="product-price">{
                                                                            ayar.mfAktarimTipi=="1" && urun.mfAktif ?  ConvertIntToN2(urun.mfIleFaturaNet) : ConvertIntToN2(urun.listeFiyati)
                                                                            }</span> TL</h5>
                                                                } 
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div>
                                            {/* card body */}

                                            <div className="card-footer p-1 px-3 pe-4">
                                                <div className="row align-items-center gy-3">
                                                    <div className="col">
                                                        <div className="d-flex flex-wrap my-n1">
                                                        <div className="align-items-center">
                                                            <button className="btn btn-white d-block text-body  fs-14"  onClick={()=>{UrunKaldir(urun.id)}}>
                                                                <div className='d-flex'><i className="bi bi-x-circle text-muted me-1 " /> <div style={{paddingTop:2}}>Kaldır</div></div>
                                                            </button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="d-flex align-items-center gap-2 text-muted">
                                                        <div>Toplam :</div>
                                                        <h5 className="fs-14 mb-0"><span className="product-line-price">{ConvertIntToN2(urun.faturaNet* (urun.mfAktif ?  (mf) :(urun.miktar)))}</span> TL</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* end card footer */}

                                        </div>
                                        {ayar.mfAktarimTipi=="0" && urun.mfAktif ?
                                            <div className="card product" >
                                            <div className="card-body p-3">
                                                <div className="row  ">
                                                    <div className='col-lg-4 mb-0 d-flex col-12'>
                                                        
                                                        <div className="mb-2" width="120px">
                                                            <div className="avatar-lg bg-light rounded p-0">
                                                                <img src={urun.resim1 == '' ? "/assets/img/default-product.jpg" : urun.resim1} style={{objectFit:'contain',width:'100%',height:'100%'}}>

                                                                </img>
                                                            </div>
                                                        </div>
                                                        <div className=" ps-2 mb-2 align-items-center" style={{width:'calc(100%+ 100px)'}}>
                                                            <h5 className="fs-14 " ><Link className="" to={`/Magaza/`+urun.malzemeId} >{urun.adi}</Link> <a href="#" className="text-dark"></a></h5>
                                                            <ul className="list-inline text-muted">
                                                                <li className="list-inline-item">Kategori : <span className="fw-medium">{urun.kategori}</span></li>
                                                            </ul> 
                                                                {
                                                                    urun.mfAktif ? 
                                                                        (
                                                                        <>
                                                                            <div className='mt-1'> 
                                                                                <span >Mf Uygulaması:</span> 
                                                                                <span className='fw-500'>
                                                                                    {
                                                                                        urun.mfSira=="1" ? (urun.malFazlasi.toString() +"+"+ urun.malFazlasiHediyesi.toString()): 
                                                                                        urun.mfSira=="2" ? (urun.malFazlasi2.toString() +"+"+ urun.malFazlasiHediyesi2.toString()) : 
                                                                                        urun.mfSira=="3" ? (urun.malFazlasi3.toString() +"+"+ urun.malFazlasiHediyesi3.toString()) : 
                                                                                        null
                                                                                    } 
                                                                                </span>

                                                                            </div>
                                                                            <div>
                                                                                <span>Adet:</span> 
                                                                                <span className='fw-500'>
                                                                                    {
                                                                                        urun.mfSira=="1" ? (urun.malFazlasiHediyesi.toString()): 
                                                                                        urun.mfSira=="2" ? (urun.malFazlasiHediyesi2.toString()) : 
                                                                                        urun.mfSira=="3" ? (urun.malFazlasiHediyesi3.toString()) : 
                                                                                        null
                                                                                    } 
                                                                                </span>
                                                                            </div>
                                                                            </>
                                                                        )
                                                                    :   
                                                                    <div className="input-step mt-1">

                                                                        <button type="button" className="minus" onClick={()=>AdetGuncelle(2,urun)}>–</button>

                                                                        <input
                                                                        type="number"
                                                                        className="product-quantity"
                                                                        defaultValue={parseFloat(urun.miktar)}  
                                                                        pattern="[0-9]*"
                                                                        onchange={(e) => {
                                                                            const yeniMiktar = parseFloat(e.target.value); 
                                                                            AdetGuncelle(3, urun,yeniMiktar);  
                                                                            }}
                                                                        />
                                                                        <button type="button" className="plus" onClick={()=>AdetGuncelle(1,urun)}>+</button> 

                                                                    </div>
                                                                }
                                                                
                                                            
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-8  col-md-12 d-flex  m-0 '>
                                                        {
                                                            ayar.iskGoster ? (
                                                                
                                                                <div className='col-lg-10 col-md-12 justify-content-start row mb-2' >
                                                                    {/* Dizi üzerinde dönerek her bir öğeyi işleyin */} 
                                                                    <div className='col-4 col-md-auto  mb-4 text-center' style={{minWidth:50}} key={index} >
                                                                        <p className='fw-medium text-muted fs-13 mb-2'>MF İndirimi</p>
                                                                        <span >100%</span>
                                                                    </div> 
                                                                
                                                                </div>
                                                            ) : ""
                                                        }
                                                        <div className={  ayar.iskGoster ?  "col-lg-2 col-md-12  p-0 m-0 mb-2" :"col-lg-12 col-md-12 mb-2" } >
                                                            <div className="text-lg-end">
                                                            <p className=" fw-medium  text-muted mb-1"> Ürün Fiyatı:</p>
                                                                {
                                                                    urun.listeFiyati != urun.faturaNet 
                                                                    ? 
                                                                    <div className='clearfix'>
                                                                        <h5 className="fs-14" style={{textDecoration:"line-through"}}><span id="ticket_price" className="product-price">{ConvertIntToN2(urun.listeFiyati)}</span> TL</h5> 
                                                                        <h4 className="fs-14"><span id="ticket_price" className="product-price">0,00</span> TL</h4> 
                                                                    </div>
                                                                        : <h5 className="fs-14"><span id="ticket_price" className="product-price">0,00</span> TL</h5>
                                                                } 
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div>
                                            {/* card body */}

                                            <div className="card-footer p-1 px-3">
                                                <div className="row align-items-center gy-3">
                                                    <div className="col">
                                                        <div className="d-flex flex-wrap my-n1">
                                                        <div className="align-items-center">
                                                            <button className="btn btn-white d-block text-body  fs-14"  onClick={()=>{UrunKaldir(urun.id)}}>
                                                                <div className='d-flex'><i className="bi bi-x-circle text-muted me-1 " /> <div style={{paddingTop:2}}>Kaldır</div></div>
                                                            </button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="d-flex align-items-center gap-2 text-muted">
                                                        <div>Toplam :</div>
                                                        <h5 className="fs-14 mb-0"><span className="product-line-price">{ 
                                                            ConvertIntToN2(0)
                                                        }</span> TL</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* end card footer */}

                                            </div>
                                        :null} 
                                </div>
                                )
                            }
                            
                            )
                        }
                    </>   
                </div> 
                 
                <div className="col-xl-4">
                <div className="sticky-side-div">
                <div className="card">
                    <div className="card-header border-bottom-dashed">
                    <h5 className="card-title mb-0">Fiyatlandırma</h5>
                    </div>
                    <div className="card-body pt-2">
                    <div className="table-responsive">
                        <table className="table table-borderless mb-0">
                            <tbody>
                                <tr>
                                <td>Ara Toplam:</td>
                                <td className="text-end" id="cart-subtotal">{ConvertIntToN2(state.aratoplam)} TL</td>
                                </tr>
                                <tr className='p-0'>
                                    <td className='pb-0'>Toplam İndirim<span className="text-muted" /> : </td>
                                    
                                    <td className="text-end pb-0" id="cart-discount">{ConvertIntToN2(state.indirim)} TL</td>  
                                </tr>
                                {
                                    ayar.eczaciModulu && state.mfindirim>0 ? 
                                        <tr className='fw-500'>
                                            <td  className='pt-0'>MF İndirim<span className="text-muted fw-500" /> : </td>
                                            
                                            <td className="text-end fw-500 pt-0" id="cart-discount">{ConvertIntToN2(state.mfindirim)} TL</td>  
                                        </tr> 
                                    :null
                                }
                                <tr>
                                <td>KDV: </td>
                                <td className="text-end" id="cart-tax">{ConvertIntToN2(state.kdvtoplam)}  TL</td>
                                </tr>
                                <tr className="table-active">
                                <th>Toplam  :</th>
                                <td className="text-end">
                                    <span className="fw-semibold" id="cart-total">
                                    {ConvertIntToN2(state.toplam)} TL
                                    </span>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/* end table-responsive */}
                    </div>
                </div>
                <div className="card">
                    <div className="card-header bg-soft-light ">
                        Açıklama
                    </div>
                    <div className="card-body bg-soft-light border-bottom-dashed ">
                    <div className="hstack gap-3 w-100">
                        <div className="form-group w-100">
                            <textarea className="form-control w-100" rows={3}  placeholder="Açiklama Giriniz." value={sepetaciklama}  onChange={(e)=>{setSepetaciklama(e.target.value)}}/>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end"> 
                    <button  className="btn btn-danger btn-label float-right mx-2" onClick={()=>{UrunKaldir(0)}}>
                        Temizle
                    </button> 
                    <button  className="btn btn-success btn-label right float-right" onClick={()=>{Tamamla()}}>
                        Tamamla 
                    </button>
                </div>
                </div>
                {/* end stickey */}
                </div>
            </div>
            
        </div>
    )
}
 