import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div>
        
      <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
        {/* auth-page content */}
        <div className="auth-page-content overflow-hidden p-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8">
                <div className="text-center">
                  <img src="/assets/img/error400-cover.png" className="img-fluid error-500-img error-img"/>

                  <div className="mt-3">
                    <h3 className="text-uppercase">Üzgünüm,sayfa bulunamamaktadır😭</h3>
                    <p className="text-muted mb-4">Aradığınız sayfa mevcut değil!</p>
                    <Link to="/"  className="btn btn-primary">Anasayfaya Geri Dön</Link>

                  </div>
                </div>
              </div>{/* end col */}
            </div>
            {/* end row */}
          </div>
          {/* end container */}
        </div>
        {/* end auth-page content */}
      </div>

    </div>
  )
}

export default NotFound