import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/Header'
import axiosInstance from '../../api/api';
import { DataGrid } from 'devextreme-react';
import { Column, Pager, Paging } from 'devextreme-react/data-grid'; 
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import { Link, useNavigate } from 'react-router-dom';


export default function Faturalar() {
    const [datalist,setdatalist] = useState([]);
    const [filtrdata,setfiltrdata] = useState([]);
    const [search,setSearch] = useState(null);
    const [startdate,setStartdate] = useState(null);
    const [enddate,setEnddate] = useState(null);
    const [statusdata,setStatusdata] = useState(null);
    const dataGridRef = useRef(null);
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);

    useEffect(()=>{
        const fetchdata = async() =>{
            let res = await axiosInstance.get("Fatura/GetFaturalar");
            if(res !=null && res.status == 200 ){ 
                setfiltrdata(res.data.data);
                setdatalist(res.data.data); 
            } 
        }
        fetchdata();
        
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);  
      };
   
      handleResize(); 
      window.addEventListener('resize', handleResize);
   
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    },[])

    function formatTarih(tarih) {
        const gun = String(tarih.getDate()).padStart(2, '0');
        const ay = String(tarih.getMonth() + 1).padStart(2, '0');
        const yil = tarih.getFullYear();
        const saat = String(tarih.getHours()).padStart(2, '0');
        const dakika = String(tarih.getMinutes()).padStart(2, '0');
      
        return `${gun}/${ay}/${yil} ${saat}:${dakika}`;
    }

    function onCustomizeColumns(columns){  
        for(var i = 0; i < columns.length; i++) {
        columns[i].cssClass = 'table-current-list-td';  
        } 
    } 

    const onExportButtonClick = () => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
    
        exportDataGrid({
          component: dataGridRef.current.instance,
          worksheet: worksheet, 
        }).then(function() {
          workbook.xlsx.writeBuffer().then(function(buffer) {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Faturalar.xlsx');
          });
        });
    };

    const filtreUygula = () => {
        let filtrdataclone = [...datalist];
    
        if (startdate != null) {
          filtrdataclone = filtrdataclone.filter((x) => new Date(x.tarih.toString()) >= startdate);
        }
    
        if (enddate != null) {
          filtrdataclone = filtrdataclone.filter((x) => new Date(x.tarih.toString()) <= enddate);
        }
    
        if (statusdata == 0 || statusdata == 1) {
          filtrdataclone = filtrdataclone.filter((x) => x.durumu.toString() == statusdata.toString());
        }
    
        if (search != null) {
          const upperSearch = search.trim().toUpperCase();
          filtrdataclone = filtrdataclone.filter(
            (p) =>
              p.aciklama==null ? true : p.aciklama.toString().toUpperCase().includes(upperSearch) ||
              p.belgeNo==null ? true :p.belgeNo.toString().toUpperCase().includes(upperSearch) 
          );
        }
        setfiltrdata(filtrdataclone);
    };

    
    const SiparisEkle = () =>{
        navigate("/Magaza"); 
    }
    
    const ConvertIntToN2 = (number) =>{
        if(number!=null){
    
        const str = number.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
        return str;
        }else{
        return null;
        }
    }
    
    return (
        <div>
                {/*----------Page Content Header---------*/}
                <Header anadizin="Anasayfa" baslik="Faturalar" detaydizin="Faturalar" anadizinyol="index/"/>

                {/*----------Page Content Body---------*/}
                <div className="row page-content-wrapper">
                <div className="col-lg-12">
                    <div className="card" id="customerList">
                    <div className="card-header border-bottom-dashed">
                        <div className="row g-4 align-items-center justify-content-center ">
                        <div className="col">
                            <div>
                            <h5 className="card-title mb-0 text-center text-sm-start">Faturaların Listesi</h5>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="d-flex flex-wrap align-items-center gap-2">
                                <button type="button" className="btn btn-success add-btn" data-bs-toggle="modal" id="create-btn" data-bs-target="#showModal" onClick={SiparisEkle}><i className="ri-add-line align-bottom me-1" /> Sipariş Ekle</button>
                                <button type="button" className="btn btn-info" onClick={onExportButtonClick}><i className="ri-file-download-line align-bottom me-1" /> Dışarı Aktar</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="card-body border-bottom-dashed border-bottom">
                        <form>
                        <div className="row g-3">
                            <div className="col-xl-6">
                            <div className="search-box">
                            <input type="text" className="form-control search"  onChange={(e)=>{setSearch(e.target.value)}} placeholder="Aramak İstediğiniz Fatura İle İlgili Bir Kelime Giriniz." />

                            </div>
                            </div>
                            {/*end col*/}
                            <div className="col-xl-6">
                            <div className="row g-3">
                                <div className="col-sm-3">
                                <div >
                                    <div className="form-group">
                                    <div className="input-group date fs-13" id="datetimepicker1">
                                    <input type="date" className="form-control fs-13" onChange={(e)=>{if(e.target.value.length>0){setStartdate(new Date(e.target.value.toString()))}else{setStartdate(null)}}} />

                                    </div>
                                    </div>
                                </div>
                                </div>
                                {/*end col*/}
                                <div className="col-sm-3">
                                <div >
                                    <div className="form-group">
                                    <div className="input-group date fs-13" id="datetimepicker1">
                                    <input type="date" className="form-control fs-13" onChange={(e)=>{if(e.target.value.length>0){setEnddate(new Date(e.target.value.toString()))}else{setEnddate(null)}}} />
                                    </div>
                                    </div>
                                </div>
                                </div>
                                {/*end col*/}
                                <div className="col-sm-3">
                                <div >
                                    <div className="form-group">
                                    <select  className="form-select py-2 px-3 fs-13" onChange={(e)=>{setStatusdata(e.target.value)}}>
                                        <option title="Tüm" value={2}>Tüm</option>
                                        <option title="Geçerli" value={0}>Geçerli</option>
                                        <option title="İptal Edildi" value={1}>İptal Edildi</option>
                                    </select>
                                    </div>
                                </div>
                                </div>
                                {/*end col*/}
                                <div className="col-sm-3">
                                <div>
                                    <button type="button" className="btn btn-primary w-100" onClick={filtreUygula}> <i className="ri-equalizer-fill me-2 align-bottom" />Filtrele</button>

                                </div>
                                </div>
                                {/*end col*/}
                            </div>
                            </div>
                        </div>
                        {/*end row*/}
                        </form>
                    </div>
                    <div className="card-body p-0">
                        <div className="c">
                            <DataGrid  className='table-product-list w-100' 
                                allowColumnReordering={true}
                                rowAlternationEnabled={true}
                                showBorders={false}
                                columnHidingEnabled={true}
                                ref={dataGridRef}
                                dataSource={filtrdata}
                                customizeColumns={onCustomizeColumns}>

                                <Pager  showInfo={true} allowedPageSizes={[20,50,100]} showPageSizeSelector={true} visible={true}  showNavigationButtons="compact"/>
                                <Paging defaultPageSize={20} /> 
                                
                                <Column dataField='Aksiyon' alignment='center'  width={80} cellRender={data=>{
                                    var element = data.data;
                                    return (
                                        <ul className="list-inline hstack gap-2 mb-0 justify-content-center">
                                            <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Göster">
                                                
                                                <Link to={"/FaturaGoruntule/"+element.belgeId} className="text-primary d-inline-block">
                                                <i className="bi bi-eye-fill fs-16" />
                                            </Link>
                                            </li>
                                        </ul>
                                    );
                                }}/>

                                <Column dataField='tarih' width={120} dataType='datetime' format={(date) => {
                                    
                                    return formatTarih(new Date(date));
                                    }} alignment='center'/> 

                                <Column dataField='belgeNo' caption="No" alignment='center' width={130}/>
                                <Column dataField='turYazi' caption="Fatura Türü" alignment='center' width={140} />
                                 
                                <Column dataField='aciklama' alignment='center'  width={450}  cellRender={data=>{
                                    let element = data.data;
                                    return(
                                        <div style={{width: "100%" }}>  

                                                <span style={{whiteSpace:'pre-line'}} >{element.aciklama}</span> 
                                        </div>
                                    );
                                }}/> 
                                <Column dataField='net' alignment='center'  width={150} cellRender={data=>{
                                        let element = data.data;
                                        const decimalValue = parseFloat(element.net);
                                            return (
                                                <span>{ConvertIntToN2(decimalValue)}</span>
                                            ); 
                                    }}/>
                                <Column dataField='kdv' alignment='center'  width={150}cellRender={data=>{
                                        let element = data.data;
                                        const decimalValue = parseFloat(element.kdv);
                                            return (
                                                <span>{ConvertIntToN2(decimalValue)}</span>
                                            ); 
                                    }}/>

                                <Column dataField='genelToplam' alignment='center'  width={170}  cellRender={data=>{
                                        let element = data.data;
                                        const decimalValue = parseFloat(element.genelToplam);
                                            return (
                                                <span>{ConvertIntToN2(decimalValue)}</span>
                                            ); 
                                }}/>
                                <Column dataField='efatura' alignment='center'  width={100} cellRender={data=>{
                                    var element = data.data;
                                    return(
                                        <div>
                                            <ul className="list-inline hstack gap-2 mb-0 justify-content-center">
                                                <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top">
                                                    <input type="checkbox" checked={element.efatura} disabled />
                                                </li>
                                            </ul>
                                        </div>
                                    )
                                }}/>

                            </DataGrid>
                        </div>
                    </div>
                    </div>
                </div>
                {/*end col*/}
                </div>
            </div>
    )
}
 