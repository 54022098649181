import React, { useEffect, useState } from 'react'
import axiosInstance from '../../api/api';
import { Link, useParams } from 'react-router-dom';

export default function FaturaYazdir() {
    const [htmldata,sethtmldata] = useState("");
    var {id} = useParams();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); 
     
    useEffect(()=>{
        const fetchdata = async() =>{
            var resp = await axiosInstance.get("Fatura/GetFaturaAsHtml/"+id); 
            setLoading(true);
            
            if(resp!=null){
                if(resp.status == 200){
                    if(resp.data.hata){
                        setError(resp.data.mesaj);
                    }else{ 
                        sethtmldata(resp.data.data) 
                    }
                }
                else{
                    setError("Böyle bir fatura bulunamadı.");
                }
            }

            setLoading(false);
        }

        fetchdata();
 
    },[]); 
    const Yazdir = () =>{
        var iframe = document.getElementById("myIframe");
        iframe.contentWindow.print();
    } 
    
    return (
        <div style={{width:"100%"}}>
            <div className='navbar bg-dark mb-2'>
                <div  style={{margin:"auto",width:"1025px"}}>
                    <Link to={"/"} className='btn bg-white text-dark b-0' style={{float:"right"}} >Anasayfa</Link>
                    <button className='btn bg-white text-dark b-0 me-2' style={{float:"right"}} onClick={Yazdir}>Yazdir</button>
                </div>
            </div>
            <div style={{margin:"auto",width:"1025px"}}>
                {loading && <p>Loading...</p>}
                {error && <span style={{ color: 'red' }}>{error}</span>}

                {(htmldata!=null ) && (
                    <iframe
                    srcDoc={htmldata}
                    id='myIframe'
                    style={{ minHeight: "1357px", minWidth: "810px" }}
                    />
                )}  
            </div>
        </div>
    )
}
 