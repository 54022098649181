import React, { useEffect, useState } from 'react'
import NavMenu from './NavMenu';
import TopBar from './TopBar';
import Footer from './Footer';
import { Outlet, useNavigate } from 'react-router-dom';   
import jwtDecode from "jwt-decode";
import authService from '../services/auth-services';


function MainLayout() { 
  const [auth,setAuth] = useState(0);
  const navigate = useNavigate();

  useEffect(()=>{ 
    var control = authService.sessionControl();
      
    if(control == 1){ 
        const token = authService.getAccessToken(); 
        
        var decodedToken = jwtDecode(token);
        const expirationTimeInSeconds = decodedToken.exp;

        const currentTimeInSeconds = Date.now() / 1000; 

        if (expirationTimeInSeconds > currentTimeInSeconds) { 
          setAuth(1);
        } 
        else {
          setAuth(0);    
          navigate("/Login") 
        }
      
    }else{
        navigate("/Login") 
    } 
     
  },[])

  const OnClickHandler = () =>{
    document.querySelector("#mainwrapper").classList.remove("nav-open");
    document.querySelector(".close-layer").classList.remove("visible");
  }

  if (auth==1) {
    return (
      <div>
        <div className="page fs-13" id='mainwrapper'>
          {/*----------Sidebar---------*/}
          <div className="app-menu navbar-menu">
            <NavMenu/>
          </div>
          {/*----------Main Content---------*/}
          <div className="main-content">
            {/*----------TopBar---------*/}
            <div id="page-topbar">
              <TopBar/>
            </div>
            {/*----------Page Content---------*/}
            <div className="page-content">
              <div className="container-fluid">
                <Outlet/>
              </div>
            </div>
            
            <div className="close-layer" onClick={OnClickHandler}/> 
            {/*----------Footer---------*/}
            <footer className="footer">
              <Footer/>
            </footer> 
          </div>
        </div>
      </div>
    );
  }  
}

export default MainLayout