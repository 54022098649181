import React from 'react'
import { Link } from 'react-router-dom' 

function Header(props) {
  return (
    <div className="row">
      <div className="col-12 px-0">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">{props.baslik}</h4>

              <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                            <Link to={props.anadizinyol}>{props.anadizin}</Link>

                        </li>
                        <li className="breadcrumb-item active">{props.detaydizin}</li>
                  </ol> 
              </div>

          </div>
      </div>
  </div>
  )
}

export default Header