import Header from '../../components/Header' 
import React, { useMemo, useState,useEffect ,useRef} from 'react';
import DataGrid, { Column, Editing, Popup, Paging,Sorting,Pager, Lookup, Form, Selection, RequiredRule, StringLengthRule } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import axiosInstance from '../../api/api';
import lodash  from "lodash"
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';  


export default function Malzemeler() {
  const [datalist,setdatalist] = useState(null); 
  const [isMobile, setIsMobile] = useState(false); 
  const [kategorilist,setkategorilist] = useState([]);
  const [altkategorilist,setaltkategorilist] = useState([]);
  const [kategorifiltr,setkategorifiltr] = useState([]);
  const [altkategorifiltr,setaltkategorifiltr] = useState([]);
  const [filtr,setfiltr] = useState({stockStatus : 2,search : null,sortedfield : "MalzemeId",sortedtype : true});
  const dataGridRef = useRef(null); 
  const [ayar,setAyar] = useState({
    stokGoster: "0",
    fiyatGoster: true,
    iskGoster: true,
    b2bAmbarKodu: "-1",
    negatifTumAmbarKontrol: true,
    negatifStokSiparisKayit: false,
    fiyatSira: "-1",
    acikHesapOdeme: true,
    krediOdeme: true,
    eczaciModulu: false,
    altKategoriGozuksun: true,
    kategoriGozuksun: true,
    kdvliFiyatGozuksun: true,
    kdvsizFiyatGozuksun: true,
    mfAktarimTipi: "0"
  });  
 
  const renderHeaderCell = (headerData) => {
    return <div className="custom-header-cell">{headerData.column.caption}</div>;
  };

  function onCustomizeColumns(columns){  
     for(var i = 0; i < columns.length; i++) {
      columns[i].cssClass = 'product-list-td';  
    } 
  } 

  useEffect(()=>{
    const fetchgroup = async() =>{
        const res2 = await axiosInstance.get("Erp/GetUygulamaAyarlari");
        setAyar(res2.data); 

        if(res2.data.kategoriGozuksun || res2.data.altKategoriGozuksun){
            const res = await axiosInstance.get("Malzeme/GetMalzemeGruplari");

            if(res != null && res.status ==200){
                if(res.data.data.malzemeKategorileri != null){
                  setkategorilist(res.data.data.malzemeKategorileri);
                }
    
                if(res.data.data.malzemeAltKategoriler != null){
                    setaltkategorilist(res.data.data.malzemeAltKategoriler);
                }
            }
        } 
    }
    fetchgroup(); 


    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Örneğin, 768 pikselin altındaki genişlikleri mobil olarak kabul ediyoruz
    }; 


    handleResize(); 
    window.addEventListener('resize', handleResize); 
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };


  },[])

  const store = useMemo(()=>{  

    return new CustomStore({
      key: 'malzemeId',
      load(loadOptions) {  
        const requestOptions = {
          method: 'POST',
          url: process.env.REACT_APP_SERVER_BASE_URL+"Malzeme/GetMalzemeler",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJUb2tlbkluZm8iOiJ7XCJVc2VySWRcIjo3LFwiVGVuYW50SWRcIjo3LFwiQ29tcGFueUlkXCI6NyxcIkZ1bGxOYW1lXCI6XCJIYWthbiBcXHUwMEQ2elwiLFwiQ29tcGFueU5hbWVcIjpcIlF1YW50dW1cIn0iLCJuYmYiOjE3MDA0NjQ3NDIsImV4cCI6MTcwMzA1Njc0MiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdCIsImF1ZCI6Imh0dHA6Ly9sb2NhbGhvc3QifQ.gnZ22I_oBjIOrhP1mNtdgBIWD6CUvE_JvWfhvvWbjG0',
          },
          data: {
            skip: 'skip' in loadOptions ? loadOptions.skip : 0,
            take: 'take' in loadOptions ? loadOptions.take : 10,  
            keyword: filtr.search,
            categories: kategorifiltr.length>0 ? kategorifiltr: null,
            subCategories: altkategorifiltr.length>0 ? altkategorifiltr : null,
            stockStatus: filtr.stockStatus, 
            sortedfield: filtr.sortedfield,
            sortedtype: filtr.sortedtype,
          },
        };

        return axiosInstance(requestOptions)
        .then((response) => {  
            if(response !=null && response.status == 200){
              const res = response.data;    
            
              setdatalist((prevDataList) => { 
                  return loadOptions.skip  === 0 ? [...res.data] : [...prevDataList, ...res.data];
              });
  
              return { data: res.data, totalCount: res.totalcount };
            }
            else{ 
              throw new Error("Mağaza Verisi Çekilemedi");
            } 
        })
        .catch((error) => {
            throw new Error(error.message);
        });
      }
    })
  },[filtr,kategorifiltr,altkategorifiltr]);  
 
  const SepeteEkle = (id,mfsira=null,mfaktif=false) => {
    var malzeme = datalist.filter(function(item){
      return item.malzemeId == id;
    });   
    var miktardom = parseFloat(document.querySelector('#malzeme'+id).value);
  
    if(mfaktif){
      miktardom=1;
    }

    const requestDto = {
        MalzemeId: malzeme[0].malzemeId,
        MfAktif: mfaktif,
        MfSira: mfsira,
        Miktar: parseFloat(miktardom)
    };


    axiosInstance.post("Sepet/CreateSepetItem",requestDto).then(res=>{   
        if(res!=null){
            if(res.status == 200){
              toast.success("Sepete Eklendi.");  

            }
            else{ 
              var mesaj = res.response.data;
              toast.error(mesaj,{
                autoClose:2000
              })
            }
        }
    });   
  }   
 
  const InputHandle = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    const newfilter = lodash.cloneDeep(filtr);
    newfilter[name] = value;
    setfiltr(newfilter);
  };

  const SortedHandle = (e) =>{
    let value = e.target.value;
    var tip = true;
    var alan = "MalzemeId";
  
    switch (value) {
      case "1":
          tip = false;
          alan = "MalzemeId";
        break;
      case "2":
        tip = true;
        alan = "MalzemeId";
      break;
      case "3":
        tip = true;
        alan = "Stok";
      break;
      case "4":
        tip = false;
        alan = "Stok";
      break;
      case "5":
        tip = true;
        alan = "Adi";
      break;
      case "6":
        tip = false;
        alan = "Adi";
      break;
      case "7":
        
        tip = true;
        alan = "Aciklama";
      break;
      case "8":
        tip = false;
        alan = "Aciklama";
      break;
    }
    const newfilter = { ...filtr, sortedtype: tip, sortedfield: alan };
    setfiltr(newfilter);
  }

  const KategoriHandle = (e) =>{
      let eleman = e.target.value;
      const liste = [ ...kategorifiltr];

      if(liste.indexOf(eleman) != -1){
        liste.splice(liste.indexOf(eleman),1);
      }

      else{
          liste.push(eleman)
      } 
      setkategorifiltr(liste);
  }

  const AltKategoriHandle = (e) =>{
      let eleman = e.target.value;
      const liste2 = [ ...altkategorifiltr];

      if(liste2.indexOf(eleman) != -1){
        liste2.splice(liste2.indexOf(eleman),1);
      }

      else{
        liste2.push(eleman)
      }
        
      setaltkategorifiltr(liste2);
  } 

  const onExportButtonClick = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: dataGridRef.current.instance,
      worksheet: worksheet
    }).then(function() {
      workbook.xlsx.writeBuffer().then(function(buffer) {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Magaza.xlsx');
      });
    });
  };
  
  const ConvertIntToN2 = (number) =>{
    if(number!=null){

      const str = number.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
      return str;
    }else{
      return null;
    }
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {  
      const enteredValue = event.target.value;
      const name = event.target.name;

      const newfilter = lodash.cloneDeep(filtr);
      newfilter[name] = enteredValue;
      setfiltr(newfilter);
    }
  }
  
  return (
    <div>
      <Header anadizin="Anasayfa" baslik="Anasayfa" detaydizin="Anasayfa" anadizinyol="/" />

      <div className="row page-content-wrapper">
      
      <div className="col-xl-2 col-md-12 ">
        <div className="card ">
          <div className="card-header pb-4">
            <div className="d-flex mb-3">
              <div className="flex-grow-1">
                <h5 className="fs-16">Filtreler</h5>
              </div>
              <div className="flex-shrink-0">
              </div>
            </div>
          </div>
          <div className="card-body border-bottom">
            <div className="filter-choices-input mb-2">
              <input type="text" placeholder="Arama Metni giriniz." name="search"  className="form-control w-100" onKeyDown={handleKeyDown} /*onBlur={e=>InputHandle(e)}*//>
            </div>
          </div>
          {
            ayar.kategoriGozuksun ? 
            <div className="card-body border-bottom" style={{maxHeight:300,overflowY:'scroll'}}>
              <div>
                  <p className="text-muted text-uppercase fs-12 fw-medium mb-2">Kategori</p>
                  {kategorilist && kategorilist.map((item, index) => (
                    <div key={index} className="d-flex flex-column gap-2 mt-1 filter-check">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue={item}
                          id={`kategoriradiobutton_${item}`}
                          name='kategori'
                          onClick={KategoriHandle}
                        />
                        <label className="form-check-label" htmlFor={`kategoriradiobutton_${item}`}>
                          {item}
                        </label>
                      </div>
                    </div>
                  ))}
              </div>
            </div> : null
          }
          {
            ayar.altKategoriGozuksun? 
              <div className="card-body border-bottom"  style={{maxHeight:300,overflowY:'scroll'}}>
                <div>
                    <p className="text-muted text-uppercase fs-12 fw-medium mb-2">Alt Kategori</p>
                    {altkategorilist && altkategorilist.map((item, index) => (
                      <div key={index} className="d-flex flex-column gap-2 mt-1 filter-check">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue={item}
                            id={`altkategoriradiobutton_${item}`}
                            name='altkategori'
                            onClick={AltKategoriHandle}
                          />
                          <label className="form-check-label" htmlFor={`altkategoriradiobutton_${item}`}>
                            {item}
                          </label>
                        </div>
                      </div>
                    ))}
                </div>
            </div> 
            :null
          }
          

          <div className="card-body border-bottom">
            <div>
              <p className="text-muted text-uppercase fs-12 fw-medium mb-2">Stok Durumu</p>
              <div className="d-flex flex-column gap-2 mt-4 filter-check">
                <div className="form-check">
                  <input className="form-check-input" type="radio" defaultChecked={filtr.stockStatus === 2} value={2} name="stockStatus" id="stokradiobutton1"    onChange={InputHandle}/>
                  <label className="form-check-label" htmlFor="stokradiobutton1">Tümü</label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" value={1} name="stockStatus" id="stokradiobutton2"   onChange={InputHandle}/>
                  <label className="form-check-label" htmlFor="stokradiobutton2">Var</label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" value={0} name="stockStatus" id="stokradiobutton3"   onChange={InputHandle}/>
                  <label className="form-check-label" htmlFor="stokradiobutton3">Yok</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-10 col-md-12 ">
        <div className="card ">
          <div className="card-header b-0">
            <div className="row align-items-center gy-3">
              <div className="col text-center text-md-start">
                <h5 className="card-title mb-0 fs-16">Ürünlerin Listesi</h5>
              </div>
              <div className="col-auto">
                <div className="d-flex justify-content-center gap-2 flex-wrap">
                <select style={{border:'1px solid black',borderRadius:'5%',padding:'0px 5px'}} onChange={SortedHandle} defaultValue={1}>
                    <option value={1}>Sıralama</option> 
                    <option value={2}>Varsayılan(Artan)</option>
                    <option value={3}>Stok(Artan)</option>
                    <option value={4}>Stok(Azalan)</option>
                    <option value={5}>Adi(Artan)</option>
                    <option value={6}>Adi(Azalan)</option>
                    <option value={7}>Aciklama(Artan)</option>
                    <option value={8}>Aciklama(Azalan)</option>
                </select>
                  <button type="button" className="btn btn-info" onClick={onExportButtonClick}>
                    <span>Dışarı Aktar</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body p-0">

          <DataGrid className='table-product-list' width="100%" 
              remoteOperations={true}
              ref={dataGridRef}
              dataSource={store}
              allowColumnReordering={true}
              rowAlternationEnabled={true}
              showBorders={false}
              allowedPageSizes={false}  
              customizeColumns={onCustomizeColumns}
              keyExpr="malzemeId">
                
            <Sorting mode="none"/>
             
            <Column dataField="malzemeId" caption='Id' alignment='left' visible={false}/>
 
            <Column dataField="Resim" alignment='center'  hidingPriority={8} width={isMobile ? 90 : 120} cellRender={(cellData)=> {
                return (
                  <div className='row p-0 justify-content-center ' style={{backgroundColor:'transparent',height:"60px" }} >
                      <img id='mainimg' className='img-fluid m-0 p-0' 
                      src={cellData.data.resim=="" ? "/assets/img/default-product.jpg" : cellData.data.resim} 
                      style={{ objectFit: "contain", height:"100%",width:"100%",maxWidth:106}}
                      />
                  </div> 
                 );
            }}>
            
            </Column>

            <Column dataField="adi" width={isMobile ? 120 : 250}  hidingPriority={16}  alignment='center'  cellRender={data=>{
                let element = data.data;
                return(
                  <div style={{width:"100%"}}>  
                    <Link to={"/Magaza/"+element.malzemeId} style={{color:"black",fontWeight:"500"}}> 
                        <span style={{whiteSpace:'pre-line'}} >{element.adi}</span>
                    </Link>
                  </div>
                );
            }}/>

            

            <Column dataField="kodu" alignment='center' width={140}  hidingPriority={4} headerCellRender={renderHeaderCell} cellRender={data=>{
                let element = data.data;
                return(
                  <div>
                    <Link to={"/Magaza/"+element.malzemeId} style={{color:"black",fontWeight:"500"}}>{element.kodu}</Link>
                  </div>
                );
            }}/>

            <Column dataField="birim" caption='Birim' hidingPriority={5} width={140} alignment='center'  />


            {
              ayar.fiyatGoster && ayar.kdvsizFiyatGozuksun ? (
                  <Column dataField="kdvsizListeFiyati" caption='Liste Fiyati (Kdvsiz)'  hidingPriority={11}  width={160} alignment='center' cellRender={(data) => {
                    let element = data.data;
                    const decimalValue = parseFloat(element.kdvsizListeFiyati); 
                    return <span>{ConvertIntToN2(decimalValue)}</span>;
                      
                }} />
                ) : ""
            }

            {
              ayar.fiyatGoster && ayar.kdvliFiyatGozuksun ? (
                  <Column dataField="listeFiyati" caption='Liste Fiyati (Kdvli)' width={160}  hidingPriority={12} alignment='center' cellRender={(data) => {
                    let element = data.data;
                    const decimalValue = parseFloat(element.listeFiyati); 
                    return <span>{ConvertIntToN2(decimalValue)}</span>;
                      
                }} />
                ) : ""
            } 

            {
              <Column dataField="iskOran" width={80} alignment='center'   hidingPriority={7} cellRender={(render)=>{
                let element = render.data;
                return (
                  <span>{ConvertIntToN2(element.iskOran)}</span>
                )
              }}/>
            }
            {
              ayar.eczaciModulu ? 
                <Column caption="Isk. MF" width={isMobile ? 80 : 160} alignment='center'  hidingPriority={10} cellRender={(render)=>{
                  let element = render.data;
                  return (
                    <div className='row justify-content-center px-1 py-1' > 
                        {element.malFazlasi>0 ? (<button style={{background:'#6c757d'}} className='btn btn-info col-9 border mb-1 p-1 ' onClick={()=>{SepeteEkle(element.malzemeId,"1",true)}}>{element.malFazlasi} + {element.malFazlasiHediyesi}</button>) : null}
                        {element.malFazlasi2>0 ? (<button style={{background:'#6c757d'}} className='btn btn-info col-9 border mb-1 p-1 ' onClick={()=>{SepeteEkle(element.malzemeId,"2",true)}}>{element.malFazlasi2} + {element.malFazlasiHediyesi2}</button>) : null}
                        {element.malFazlasi3>0 ? (<button style={{background:'#6c757d'}} className='btn btn-info col-9 border p-1 mb-1' onClick={()=>{SepeteEkle(element.malzemeId,"3",true)}}>{element.malFazlasi3} + {element.malFazlasiHediyesi3}</button>) : null} 
                    </div>
                  );
                }}/>
              : null
            } 
            { 
              ayar.eczaciModulu ? 
              <Column caption="MF'li Fiyat" width={140}  alignment='center' hidingPriority={14} cellRender={(render)=>{
                let element = render.data;
                return (
                  <div className='row justify-content-center px-1 py-1'> 
                      {(!(element.malFazlasi>0) &&!(element.malFazlasi2>0) &&!(element.malFazlasi3>0)) ?
                       (<span  className='col-9 border mb-1 p-1 '>{ConvertIntToN2(element.mf1ListeFiyat)}</span>) :null}
                      {element.malFazlasi>0 ? (<span  className='col-9 border mb-1 p-1 '>{ConvertIntToN2(element.mf1ListeFiyat)}</span>) : null}
                      {element.malFazlasi2>0 ? (<span className=' col-9 border mb-1 p-1 '> {ConvertIntToN2(element.mf2ListeFiyat)}</span>) : null}
                      {element.malFazlasi3>0 ? (<span className=' col-9 border mb-1 p-1 '> {ConvertIntToN2(element.mf3ListeFiyat)}</span>) : null}
                  </div>
                );
              }}/> : <Column dataField="faturaNet" caption='Fatura Net' hidingPriority={15}   width={160} alignment='center' cellRender={(data) => {
                let element = data.data;
                const decimalValue = parseFloat(element.faturaNet); 
                return <span>{ConvertIntToN2(decimalValue)}</span>;
                  
              }} /> 
            } 

            { 
              ayar.perakendeSatisFiyatiGoster ? 
                <Column dataField="perakendeSatisFiyati" caption='PSF'  hidingPriority={9} width={120} alignment='center' cellRender={(data) => {
                  let element = data.data;
                  const decimalValue = parseFloat(element.perakendeSatisFiyati); 
                  return <span>{ConvertIntToN2(decimalValue)}</span>;
                    
                }} /> 
              :null
            } 
               
              
            
            {
              ayar.stokGoster=="2" ? 
                <Column dataField="stok" width={80}   hidingPriority={13} caption='Stok' alignment='center' 
                  cellRender={data=>{
                      let element = data.data;
                      const decimalValue = parseFloat(element.stok); 
                        return <span>{decimalValue}</span>; 
                  }}
              />

              : ayar.stokGoster=="1" ? 
                <Column dataField="stokDurumu" width={100} hidingPriority={13} caption='Stok' alignment='center' cellRender={(data)=>{
                  let element = data.data;
                  if(element.stokDurumu){
                    return <span className='badge bg-success-subtle fs-9-5 text-success text-uppercase'>Stok Var</span>
                  }
                  else{
                    return <span className='badge bg-danger-subtle fs-9-5 text-danger text-uppercase'>Stok Yok</span>

                  }
                }}/>
            : null
            } 
           
            
            <Column dataField="aciklama" width={isMobile ? 150 : 250} hidingPriority={3} alignment='center'  cellRender={data=>{
                let element = data.data;
                return(
                  <div style={{width:"100%"}}>  
                        <span style={{whiteSpace:'pre-line',width:"100%"}} >{element.aciklama}</span> 
                  </div>
                );
            }}/>
            
            <Column caption="Sipariş Miktarı" width={isMobile ? 130 : 200}  cssClass="px-2" alignment='center' cellRender={({data})=>{ 
                var malzeme = data; 
                if(!isMobile){
                  return (
                    <div className='row w-100 justify-content-center px-1'>
                      <div className='d-flex' style={{maxWidth:200}}>
                          <input className='form-control col' id={'malzeme'+malzeme.malzemeId} defaultValue={1} type='number' style={{width:80,borderRadius:"0px"}} width={80}  height={50}/>
                           
                          <button className='btn btn-secondary border-0 col-auto' type='button' style={{borderRadius:"0px",color:'white',background:'#6c757d'}} onClick={()=>{SepeteEkle(malzeme.malzemeId)}}>
                            <i className="bi bi-cart-plus fs-15" ></i>
                          </button>

                      </div>    
                    </div>  
                  )  
                }else{
                    return(
                      <div className='row w-100 justify-content-center p-0 m-0'>
                        <div className='d-flex p-0' style={{maxWidth:130}}>
                          <input className='form-control col' id={'malzeme'+malzeme.malzemeId} defaultValue={1} type='number' style={{width:80,borderRadius:"0px"}} width={80}  height={50}/>
                           
                          <button className='btn btn-secondary border-0 col-auto' type='button' style={{borderRadius:"0px",color:'white',background:'#6c757d'}} onClick={()=>{SepeteEkle(malzeme.malzemeId)}}>
                            <i className="bi bi-cart-plus fs-15" ></i>
                          </button>

                      </div>     
                      </div>  
                    )
                }
                
                 
            }}/>

            <Column dataField="barkodlar" caption='Barkodlar' hidingPriority={2} width={140} alignment='center' cellRender={({data})=>{  
              if(data.barkodlar!=null){ 
                return data.barkodlar.map((barkod, index) => (
                  <p key={index}>{barkod}</p>
                )); 
              }
            }} />
            
            
          <Column dataField="kategori" width={isMobile ? 150 : 250} hidingPriority={1} alignment='center'  cellRender={data=>{
                let element = data.data;
                return(
                  <div style={{width:"100%"}}>  
                        <span style={{whiteSpace:'pre-line',width:"100%"}} >{element.kategori}</span> 
                  </div>
                );
            }}/>

            <Column dataField="altkategori" width={isMobile ? 150 : 250} hidingPriority={0} alignment='center'  cellRender={data=>{
                let element = data.data;
                return(
                  <div style={{width:"100%"}}>  
                        <span style={{whiteSpace:'pre-line',width:"100%"}} >{element.altkategori}</span> 
                  </div>
                );
            }}/>
            
            <Paging defaultPageSize={10} className="custom-pager"/>
            <Pager showInfo={true}  showNavigationButtons="compact" showPageSizeSelector={false} visible={true} />
            
          </DataGrid> 
        </div>

          
        </div>
      </div>
      </div>
    </div>
  )
}
