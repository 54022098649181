import axios from 'axios';
import React, { useEffect,  useState} from 'react'
import { toast } from 'react-toastify';
import "./Login.css"
import { Link, useNavigate } from 'react-router-dom';  
import Cookies from 'js-cookie'

export default function Login() {     
  
  const [kod,setKod] = useState("");
  const [sifre,setsifre] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate(); 
  var tarih = new Date();
  var yil = tarih.getFullYear(); 
   
  useEffect(()=>{
       
      let data = Cookies.get('benihatirla');
      if(data!=null){
        setKod(JSON.parse(data).kod);
      }

  },[])

  const Auth = () =>{  
 
      var logindata = {
        bayiKodu:kod,
        sifre:sifre
      };

      toast.info("Lütfen Bekleyiniz...", {
        autoClose: 1000,
      });

      const postdata = async() =>{ 
        try {
            var result = await axios.post(process.env.REACT_APP_SERVER_BASE_URL + "erp/login",logindata,{
              headers: { 'Content-Type': 'application/json' },
            }) 
            var dataarray = JSON.stringify(result.data.data);  
              Cookies.set('oturum',dataarray);  

            if(isChecked){
              Cookies.set('benihatirla',JSON.stringify({kod:kod}));  
            }
            else{
              Cookies.remove('benihatirla'); 
            }

            setTimeout(() => {
              navigate("/");
            }, 1000); 

        } catch (error) { 
            console.log(error);

            if(error.response!=null){
                if(error.response.status==400){  
                    toast.error(error.response.data);
                }
                else{  
                  toast.error("Server Failed:"+new Date().toLocaleDateString('tr-TR')+" "+new Date().toLocaleTimeString('tr-TR')); 
                } 
            }else{
                toast.error("Server Failed:"+new Date().toLocaleDateString('tr-TR')+" "+new Date().toLocaleTimeString('tr-TR'));  
            } 
          
        } 
           
      } 
      postdata();
  }

  const InputHandle = (e) => {
      let name = e.target.name;
      let value = e.target.value;

      if(name=="kod"){
        setKod(value);
      }
      
      if(name=="sifre"){
        setsifre(value); 
      }
  } 
  
  const CheckboxHandle = ()=>{
    setIsChecked(!isChecked); 
  } 
  
  return(
    <>
        
      <div className="page loginpage" style={{minHeight:"100vh"}}>
        <div className="auth-page-wrapper pt-5"> 
          <div className="auth-one-bg-position auth-one-bg" id="auth-particles" >
            <div className="bg-overlay" />
            <div className="shape">
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z" />
              </svg>
            </div>
            <canvas className="particles-js-canvas-el" width={1848} height={475} style={{width: '100%', height: '100%'}} />
          </div> 
          <div className="auth-page-content">
            <div className="container" style={{maxWidth: '1140px'}}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center mt-sm-5 mb-1 text-white-50">
                    <div>
                      <a href="#" className="d-inline-block auth-logo">
                        <img src='/assets/img/koklulogo.png' alt="" height={170}  style={{opacity:"0.8"}}/>
                      </a>
                    </div>
                    <p className="mt-2 fs-15 fw-medium">Köklü Sağlık Bayi Sistemi</p>
                  </div>
                </div>
              </div> 
              <div className="row justify-content-center fs-13">
                <div className="col-md-8 col-lg-6 col-xl-5">
                  <div className="card mt-2">
                    <div className="card-body p-4">
                      <div className="text-center mt-2">
                        <h5 className="text-primary">Tekrardan Hoşgeldiniz!</h5>
                        <p className="text-muted">Devam etmek için oturum açınız.</p>
                      </div>
                      <div className="p-2 mt-4 ">
                        <div className="mb-3">
                          <label htmlFor="username" className="form-label">Bayi Kullanıcı Kodu:</label>
                          <input type="text" className="form-control" placeholder="Bayi Kullanıcı Kodunuzu Giriniz." value={kod}  name='kod' onChange={InputHandle}/>
                        </div>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="password-input">Şifre:</label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <input type="password" className="form-control pe-5 password-input" value={sifre} name='sifre' placeholder="Şifre Giriniz." onChange={InputHandle}/>
                          </div>
                        </div> 
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox"  id="auth-remember-check" onChange={CheckboxHandle}/>
                          <label className="form-check-label" htmlFor="auth-remember-check">Beni Hatırla!</label>  
                        </div>
                        <div className="mt-4">
                          <button className="btn btn-success w-100"  type="buttton" onClick={Auth}>Giriş Yapın</button>
                        </div>
                      </div>
                    </div> 
                  </div> 
                </div>
              </div> 
 
            </div> 
          </div> 
          <footer className="footer fs-13">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center">
                    <p className="mb-0 text-muted">
                      ©{yil} KöklüSağlıkBayi. Quantum Yazılım Tarafından Yapılmıştır.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </footer> 
        </div>
      </div>
    </>

  );
}


