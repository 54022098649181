import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import axiosInstance from '../../api/api';
import Header from '../../components/Header';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import { DataGrid } from 'devextreme-react';
import { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { toast } from 'react-toastify';
import { Row } from 'react-bootstrap';

const MusteriEkstre = () => {
    const {id} = useParams();
    const [datalist,setdatalist] = useState(null);
    const [filtrdata,setfiltrdata] = useState(null);
    const [startdate,setStartdate] = useState(null);
    const [enddate,setEnddate] = useState(null);
    const [search,setSearch] = useState(null);
    const [err,setErr] = useState("");
    const dataGridRef = useRef();
    const [isMobile, setIsMobile] = useState(false);

    useEffect(()=>{
        const fetchData = async() =>{
            const res = await axiosInstance.get("Cari/GetEkstreler/"+id)  
            if(res !=null ){
                if(res.status == 200){
                    setdatalist(res.data.data);
                    setfiltrdata(res.data.data);  
                } 
                else if(res.response.status == 404){
                    setErr("Müşteri bulunamadı."); 
                } 
                else{}
            }
            
        }
        fetchData();    
 
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);  
        };
     
        handleResize(); 
        window.addEventListener('resize', handleResize);
     
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    },[]);

    const onExportButtonClick = () => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
    
        exportDataGrid({
          component: dataGridRef.current.instance,
          worksheet: worksheet
        }).then(function() {
          workbook.xlsx.writeBuffer().then(function(buffer) {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'CariEkstre.xlsx');
          });
        });
    };

    const Filtreleme = () =>{
        var filtrdataclone = [...datalist];

        if(search !=null){
            filtrdataclone = filtrdataclone.filter((item)=>{
       
                return (
                   item.belgeNo.toUpperCase().includes(search.toUpperCase()) ||
                   item.aciklama.toUpperCase().includes(search.toUpperCase()) ||
                   item.turuSozel.toUpperCase().includes(search.toUpperCase()) ||
                   item.cariKodu.toUpperCase().includes(search.toUpperCase())

                 )
             })
        }

        if(startdate !=null){
            filtrdataclone = filtrdataclone.filter((item)=>{
                return (
                    new Date(item.tarih)>startdate
                )
            })
        }

        if(enddate !=null){
            filtrdataclone = filtrdataclone.filter((item)=>{
                return (
                    new Date(item.tarih)<enddate
                )
            })
        }

        setfiltrdata(filtrdataclone);
    } 
    
    const ConvertIntToN2 = (number) =>{
        if(number!=null){
    
        const str = number.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
            return str;
        }else{
            return null;
        }
    }

    function onCustomizeColumns(columns){    
        for(var i = 0; i < columns.length; i++) {
          columns[i].cssClass = 'table-current-list-td';  
        }  
    } 
    
    function formatTarih(tarih) {
        const gun = String(tarih.getDate()).padStart(2, '0');
        const ay = String(tarih.getMonth() + 1).padStart(2, '0');
        const yil = tarih.getFullYear();
        const saat = String(tarih.getHours()).padStart(2, '0');
        const dakika = String(tarih.getMinutes()).padStart(2, '0');
      
        return `${gun}/${ay}/${yil} ${saat}:${dakika}`;
    }
  
    return (
        <div>
        
            {/*----------Page Content Header---------*/}
            <Header anadizin="Cariler" baslik="Cari Ekstre" detaydizin="CariEkstre" anadizinyol="/Musteriler" />

            {/*----------Page Content Body---------*/}
            <div className="row page-content-wrapper">
                <div className="col-lg-12">

                    {err ? (<span style={{ color: 'red' }}>{err}</span>) : ""}
                    {filtrdata!=null ? ( 
                        <div className="card" id="customerList">
                            <div className="card-header border-bottom-dashed">
                            <div className="row g-4 align-items-center">
                                <div className="col">
                                <div>
                                    <h5 className="card-title mb-0">Ekstrelerin Listesi</h5>
                                </div>
                                </div>
                                <div className="col-auto">
                                <div className="d-flex flex-wrap align-items-start gap-2">
                                    <button type="button" className="btn btn-info" onClick={onExportButtonClick}><i className="ri-file-download-line align-bottom me-1" /> Dışarı Aktar</button>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="card-body border-bottom-dashed border-bottom">
                            <form>
                                <div className="row g-3">
                                    <div className="col-xl-6">
                                    <div className="search-box">
                                        <input type="text" className="form-control search" onChange={(e)=>setSearch(e.target.value)} placeholder="Aramak İstediğiniz Ekstreyle İlgili Bir Kelime Giriniz." />
                                        <i className="ri-search-line search-icon" />
                                    </div>
                                    </div>
                                    {/*end col*/}
                                    <div className="col-xl-6">
                                    <div className="row g-3">
                                        <div className="col-sm-4">
                                        <div>
                                            <div className="form-group">
                                            <div className="input-group date fs-13" id="datetimepicker1">
                                                <input type="date" className="form-control fs-13" onChange={(e)=>{setStartdate(new Date(e.target.value));}} />
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        {/*end col*/}
                                        <div className="col-sm-4">
                                        <div >
                                            <div className="form-group">
                                            <div className="input-group date fs-13" id="datetimepicker1">
                                                <input type="date" className="form-control fs-13" onChange={(e)=>{setEnddate(new Date(e.target.value));}}/>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        {/*end col*/}
                                        <div className="col-sm-4">
                                        <div>
                                            <button type="button" className="btn btn-primary w-100" onClick={Filtreleme}> <i className="ri-equalizer-fill me-2 align-bottom" />Filtrele</button>
                                        </div>
                                        </div>
                                        {/*end col*/}
                                    </div>
                                    </div>
                                </div>                
                                {/*end row*/}
                            </form>
                            </div>
                            <div className="card-body p-0">
                                <DataGrid className='table-product-list'  keyExpr="id" dataSource={filtrdata}
                                    allowColumnReordering={true}
                                    rowAlternationEnabled={true} 
                                    columnHidingEnabled={true}
                                    showBorders={true}
                                    customizeColumns={onCustomizeColumns}  
                                    ref={dataGridRef}>
                                    
                                    <Pager  showInfo={true} allowedPageSizes={[20,50,100]} showPageSizeSelector={true} visible={true}  showNavigationButtons="compact"/>
                                    <Paging defaultPageSize={20} />

                                    <Column dataField='tarih' width={150} dataType='datetime' format={(date) => { 
                                            return formatTarih(new Date(date));
                                        }} alignment='center'/>     
                                    <Column dataField='belgeNo' caption="No" width={150}  alignment='center'/>

                                    <Column dataField='turuSozel' minWidth={30}  caption='Hareket Türü' alignment='center'/> 
                                    
                                    <Column dataField='borc' minWidth={70}   alignment='center'cellRender={data=>{
                                        let element = data.data; 
                                            return (
                                                <span>{element.borc == 0 ? "''" : ConvertIntToN2(element.borc)}</span>
                                            ); 
                                    }}/> 
                                    <Column dataField='alacak' minWidth={70}   alignment='center'cellRender={data=>{
                                        let element = data.data; 
                                            return (
                                                <span>{element.alacak == 0 ? "''" : ConvertIntToN2(element.alacak)}</span>
                                            ); 
                                    }}/> 
                                    <Column dataField='bakiye' minWidth={70} alignment='center'cellRender={data=>{
                                        let element = data.data; 
                                            return (
                                                <span>{ConvertIntToN2(element.bakiye)}</span>
                                            ); 
                                    }}/> 
                                    
                                    <Column dataField="aciklama" width={isMobile ? 250 : 300}  alignment='center'  cellRender={data=>{
                                        let element = data.data;
                                        return(
                                        <div style={{width:"100%"}}> 
                                                <span style={{whiteSpace:'pre-line'}} >{element.aciklama}</span> 
                                        </div>
                                        );
                                    }}/>
                                    
                                    <Column dataField='dovizTutar' caption='Döviz Tutar' minWidth={30} alignment='center'cellRender={data=>{
                                        let element = data.data; 
                                            return (
                                                <span>{ConvertIntToN2(element.dovizTutar)}</span>
                                            ); 
                                    }}/> 
                                    
                                    <Column dataField='dovizTuruSozel' caption='Döviz Türü' minWidth={20} alignment='center'/>
                                        
                                    <Column dataField='dovizliBakiye' caption='Dövizli Bakiye' minWidth={70} alignment='center'cellRender={data=>{
                                        let element = data.data; 
                                            return (
                                                <span>{ConvertIntToN2(element.dovizliBakiye)}</span>
                                            ); 
                                    }}/> 

                                </DataGrid>

                            </div>
                        </div>
                     )  : ""}
                </div>
                {/*end col*/}
            </div>
            
        </div>
        
    )
     
    
}

export default MusteriEkstre