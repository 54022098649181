import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";

import Cookies from 'js-cookie'
 

const sessionControl = () => {
    return Cookies.get('oturum')==null ? 0 : 1;
}


const getAccessToken = () => {
    
    const user = JSON.parse(Cookies.get('oturum'));
    if(user==null){
        return null;
    }else{
        return user;
    }
}
 
const getCariAdi = () =>{
    var veriler = Cookies.get('oturum');
    
    if(veriler != null){

        const user = JSON.parse(Cookies.get('oturum'));
        var decoded = jwtDecode(user);
        return decoded.CARIADI;

    }
    else{
        return "ADMIN";
    }
}

const getSirketAdi = () =>{
    var veriler = Cookies.get('oturum');
    
    if(veriler.length >0){
        const user = JSON.parse(Cookies.get('oturum'));
        var decoded = jwtDecode(user);
        return decoded.SIRKETADI;
    }else{
        return "ADMIN";
    }
}
const getUnvan = () =>{
    var veriler = Cookies.get('oturum');
    
    if(veriler.length >0){
        const user = JSON.parse(Cookies.get('oturum'));
        var decoded = jwtDecode(user);
        return decoded.UNVAN;
    }else{
        return "ADMIN";
    }
}
const Logout = () => {
    Cookies.remove('oturum')  
    toast.success("Oturumdan çıkış yapılmıştır.",{
        autoClose:500
    }) 
}

const authService = {
    getAccessToken, 
    sessionControl,
    getCariAdi,
    getSirketAdi,
    getUnvan,
    Logout
}

export default authService