import React, { memo, useEffect, useState } from 'react' 
import axiosInstance from '../api/api';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';   
import authService from '../services/auth-services'; 

function TopBar() {
  const navigate = useNavigate(); 
  const [bakiye,setBakiye] = useState(0.00);
  
    useEffect(()=>{  
       
      const fetchtopdata = async() =>{
          var rest = await axiosInstance.get("Erp/GetToplamBakiye")
          if(rest!=null){
              setBakiye(rest.data.data);
          } 
      }
      fetchtopdata();
 
      },[]);
     
    const LogoutHandler = () =>{
        axiosInstance.post("Erp/Logout").then(res=>{
          if(res!=null){
            if(res.status ==200){
                authService.Logout();
                navigate("/Login");
            }}  
          })
        .catch(err=>
          console.log(err)
        )
    }

    const OnClickHandler = () =>{
        const mainwrapperdom = document.querySelector("#mainwrapper");
        const closelayerdom = document.querySelector(".close-layer");

        mainwrapperdom.classList.add('nav-open')
        closelayerdom.classList.add('visible'); 
    }

 
    return (
      
        <div className="layout-width">
           
          <div className="navbar-header">
            <div className="d-flex">
              <button type="button" className="d-block d-lg-none btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger" id="topnav-hamburger-icon" onClick={OnClickHandler}>
                <span className="hamburger-icon">
                  <span />
                  <span />
                  <span />
                </span>
              </button>
              <div className="header-item d-none d-lg-flex">
                <p className="user-name-text d-xl-inline-block ms-1 m-0 p-0 fs-14" style={{fontWeight: 500}}>Şirket:{authService.getSirketAdi()}</p>
              </div>
            </div>   
            <div className="d-flex">
              <div >
                <Link to="/Sepet" className="header-item  px-4 ">
                    <div id="dsepetim" type="button">
                        <i className="bi bi-cart fs-22" />
                        <span style={{marginLeft: '5px'}}>Sepetim</span>
                    </div>
                </Link>
              </div>
              <div className="header-item">
                <div className="dropdown">
                  <div id="dprofil" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="bi bi-person fs-22" />
                    <span style={{marginLeft: '5px'}}>Hesabım</span>
                  </div>
                  <div className="dropdown-menu dropdown-menu-right" style={{marginTop: '20px', width: '100%'}} aria-labelledby="dprofil">
                    <h6 className="dropdown-header">Hoşgeldiniz</h6>
                    <Link className="dropdown-item pt-1" to={"/Sifredegistir"}  style={{cursor:'pointer'}}> 
                      <i className="bi bi-gear-fill fs-15 me-1" />
                      <span>Şifre Değiştir</span>
                    </Link>  
                    <div className="dropdown-divider" />
                    <a className="dropdown-item" href="musteriler/">
                      <div><i className="bi bi-wallet2 fs-15 me-1" /><span>Bakiye :</span> </div>
                      <div className="mt-1"><span style={{fontWeight: 600}}>{bakiye} TL</span></div>
                    </a> 
                    <a className="dropdown-item" onClick={LogoutHandler} style={{cursor: 'pointer'}}>
                      <i className="bi bi-box-arrow-right fs-15  me-1" />
                      <span data-key="t-logout">Çıkış</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </div>
    )
}

export default React.memo(TopBar)