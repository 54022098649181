import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/Header'
import axiosInstance from '../../api/api';
import { DataGrid } from 'devextreme-react';
import { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Link } from 'react-router-dom';

export default function Musteriler() {
    const [datalist,setdatalist] = useState(null);
    const [filtrdata,setfiltrdata] = useState(null);
    const [search,setSearch] = useState("");
    const dataGridRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);
     
    function onCustomizeColumns(columns){  
        for(var i = 0; i < columns.length; i++) {
          columns[i].cssClass = 'table-current-list-td';  
        } 
    }
    
    const onExportButtonClick = () => { 
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
    
        exportDataGrid({
          component: dataGridRef.current.instance,
          worksheet: worksheet
        }).then(function() {
          workbook.xlsx.writeBuffer().then(function(buffer) {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Cariler.xlsx');
          });
        });
    };
     
    useEffect(()=>{
        
        const fetchdata = async() =>{
            const response = await axiosInstance.get("Cari/GetCariler")
            
            if(response !=null && response.status==200){
                setfiltrdata(response.data.data);
                setdatalist(response.data.data);
            } 
        } 
        fetchdata();
 
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);  
        }; 
        handleResize(); 

        window.addEventListener('resize', handleResize); 
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    },[])

    const Filtreleme = () =>{
        let filtrdataclone = datalist;

        filtrdataclone = filtrdataclone.filter((item)=>{
       
            return (
               item.adi.toUpperCase().includes(search.toUpperCase()) ||
               item.kodu.toUpperCase().includes(search.toUpperCase()) ||
               item.vergiNo.toUpperCase().includes(search.toUpperCase())
             )
         })

         setfiltrdata(filtrdataclone);
    }

    const ConvertIntToN2 = (number) =>{
        if(number!=null){
    
        const str = number.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
            return str;
        }else{
            return null;
        }
    }
 
    return (
        <div>
        
            {/*----------Page Content Header---------*/}
            <Header anadizin="Anasayfa" baslik="Cari Hesaplar" detaydizin="Cariler" anadizinyol="/" />

            {/*----------Page Content Body---------*/}
            <div className="row page-content-wrapper">
                <div className="col-lg-12">
                <div className="card" id="customerList">
                    <div className="card-header border-bottom-dashed">
                    <div className="row g-4 align-items-center">
                        <div className="col">
                        <div>
                            <h5 className="card-title mb-0">Müşterilerin Listesi</h5>
                        </div>
                        </div>
                        <div className="col-auto">
                        <div className="d-flex flex-wrap align-items-start gap-2">
                            <button type="button" className="btn btn-info" onClick={onExportButtonClick}><i className="ri-file-download-line align-bottom me-1" /> Dışarı Aktar</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="card-body border-bottom-dashed border-bottom">
                    <form>
                        <div className="row g-3">
                        <div className="col-xl-9">
                            <div className="search-box">
                            <input type="text" className="form-control search" onChange={(e)=>{setSearch(e.target.value)}} placeholder="Aramak İstediğiniz Müşteriyle İlgili Bir Kelime Giriniz." />
                            </div>
                        </div>
                        {/*end col*/}
                        <div className="col-xl-3 ">
                            <div className="row g-3">
                            <div className="col-sm-12">
                                <div>
                                    <button type="button" className="btn btn-primary w-100" onClick={Filtreleme}> <i className="bi bi-sliders2 me-2 align-bottom" />Filtrele</button>
                                </div>
                            </div>
                            {/*end col*/}
                            </div>
                        </div>
                        </div>
                        {/*end row*/}
                    </form>
                    </div>
                    <div className="card-body p-0">
                        <DataGrid className='table-product-list'  dataSource={filtrdata}
                            allowColumnReordering={true}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            ref={dataGridRef}
                            allowedPageSizes={true} 
                            customizeColumns={onCustomizeColumns} 
                            keyExpr="cariId" 
                            columnHidingEnabled={false}
                             style={{width:"100%"}}> 
                            
                            <Column dataField='Aksiyon' hidingPriority={9} width={80} alignment='center' cellRender={(data)=>
                            {var adres = "/MusteriEkstre/"+data.data.cariId;
                                return ( 
                                    <div>
                                        <Link to={adres}>
                                        <i className="bi bi-eye-fill fs-16 fw-light"></i> </Link>
                                    </div>  
                                )
                            }} /> 
                            <Column dataField='kodu' hidingPriority={7} width={100}  alignment='center'/>
                              
                            <Column dataField="adi" hidingPriority={8} width={isMobile ? 200 : 300}  alignment='center'  cellRender={data=>{
                                let element = data.data;
                                return(
                                    <div style={{width:"100%"}}>  

                                            <span style={{whiteSpace:'pre-line'}} >{element.adi}</span> 
                                    </div>
                                );
                            }}/>

                            <Column dataField='bakiye' hidingPriority={2} width={120} alignment='center' cellRender={data=>{
                                let element = data.data; 
                                return (
                                    <span>{ConvertIntToN2(element.bakiye)}</span>
                                ); 
                            }}/> 
                            <Column dataField='dovizliBakiye' hidingPriority={0} caption="Dövizli Bakiye" width={140} alignment='center' cellRender={data=>{
                                let element = data.data; 
                                return (
                                    <span>{ConvertIntToN2(element.dovizliBakiye)}</span>
                                ); 
                            }}/>  
                            <Column dataField='dovizTuru' hidingPriority={1} caption="Döviz Türü" width={150} alignment='center'/>
 
                            <Column dataField='tckNo' hidingPriority={6} width={150}  alignment='center'/>
                            <Column dataField='vergiNo' hidingPriority={5} width={150} alignment='center'/>
                            <Column dataField="vergiDaire" hidingPriority={4} width={350}  alignment='center'  cellRender={data=>{
                                let element = data.data;
                                return (
                                    <div style={{width:"100%"}}>  
                                            <span style={{whiteSpace:'pre-line'}} >{element.vergiDaire}</span> 
                                    </div>
                                );
                            }}/>
                             
                            <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} visible={true}/>
                            <Paging defaultPageSize={10} />
                        </DataGrid>
                    </div>
                </div>
                </div>
                {/*end col*/}
            </div>
            
        </div>
    )
}
 
